import React from "react";
import logo from "../../../images/logo-studio.svg";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import "./RestorePassword.css";
import "../Auth.css";
import Footer from "../../Footer/Footer";

const RestorePassword = () => {
  const [type, setType] = React.useState("password");
  const [passwordView, setPasswordView] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (isValid) {
      console.log(data);
      reset();
    }
  };

  return (
    <>
      <main className="content">
        <div className="gradient auth_gradient"></div>
        <section className="auth auth-full">
          <div className="auth__content register__content">
            <h1 className="auth__title">Битва покемонов</h1>
            <div className="auth__mobile">
              <a href="/login" className="auth__back"></a>
              <p className="auth__title-h2">Восстановление пароля</p>
            </div>
            <p className="register-p auth__text_small">
              Введите почту, которую указывали при регистрации
            </p>
            <form className="auth__form" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
              <div className="auth__wrap wrapper-mb">
                <input
                  type="email"
                  className="auth__input"
                  {...register("email", {
                    required: "Введите почту",
                    pattern: {
                      value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Введите почту",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                  placeholder=' '
                />
                <label className="auth__label">Почта</label>
                <span className="auth__error">{errors.email?.message}</span>
              </div>
              <button type="submit" className="auth__button">
                Восстановить
              </button>
              <Link
                to="/login"
                className="auth__text auth__registr autn__button-hidden"
              >
                Войти
              </Link>
            </form>
          </div>
        </section>
      </main>
      <div className="footer_no_line">
        <Footer />
      </div>
    </>
  );
};

export default RestorePassword;
