import React from "react";
import logo from "../../../images/logo-studio.svg";
import iconOk from "../../../images/success.svg";

import "./Сonfirmation.css";
import "../Auth.css";
import Footer from "../../Footer/Footer";

function ConfirmationOk({ title, text }) {
  return (
    <main className="content">
      <div className="gradient auth_gradient"></div>
      <section className="auth restore auth-full">
        <div className="auth__content register__content">
          <h1 className="auth__title">Битва покемонов</h1>
          <div className="confirmation">
            <img src={iconOk} className="confirmation__icon" alt="иконка" />
            <p className="auth__title-h2">{title}</p>
          </div>
          <div className="auth__confirmation-wrapper">
            <p className="auth__confirmation">{text}</p>
          </div>
          <a href="/login" className="auth__confirmation-btn">
            Перейти ко входу
          </a>
        </div>
      </section>
      <div className="footer_no_line">
        <Footer />
      </div>
    </main>

  );
}

export default ConfirmationOk;
