import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import "./Header.css";
import img from "../../images/header-img.svg";
import pokemon from "../../images/icons/ball.png";
import coaches from "../../images/icons/hat.png";
import shops from "../../images/icons/coin.png";
import rate_menu_icon from "../../images/icons/rate_menu_icon.png";
import id from "../../images/trainers/11.png";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

function Header(is_no_mobule) {
  function ResetPage(){
    Cookies.set('page_pokemons', '1', { expires: 365 });
    Cookies.set('search_pokemons', '', { expires: 365 });

    window.scrollTo(0, 0);
    $('.parent__mainblock_load_more').css('display', 'none');
  }

  function ResetCoach(){
    Cookies.set('page_pokemons', '1', { expires: 365 });
    Cookies.set('search_pokemons', '', { expires: 365 });
    Cookies.set('check_filters', '', { expires: 365 });
    Cookies.set('fix_react_sip', '1', { expires: 365 });
    $('.parent__mainblock_load_more').css('display', 'none');

    window.scrollTo(0, 0);

    $('.pokemon__filter-counter').attr('hidden', 'true');
    if ($('.radio-mobile')[0]) {
      setTimeout(function (){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
          $($('.radio-mobile')[0]).attr('checked', 'checked');
        }else{
          $($('.popup__form input.radio')[0]).attr('checked', 'checked');
        }
      }, 500);
    }
  };

  function FixResetCoach(){
    return false;
  }

  if (is_no_mobule.is_no_mobule){
    return (
        <header>
          <div className="header container">
            <div className="header__container-btn">
              <NavLink to="/" onClick={ResetPage} className="header__logo-link">
                <img className="header__img" src={img} alt="логотип"/>
              </NavLink>
              <div className="header__btns">
                <NavLink
                    to="/"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active"
                          : "header__btn";
                    }}>
                  <img className="header__btn-img" src={pokemon} alt="логотип"/>
                  Покемоны
                </NavLink>
                <NavLink
                    to="/trainers"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active k_header_button_trainers"
                          : "header__btn k_header_button_trainers";
                    }}>
                  <img className="header__btn-img" src={coaches} alt="тренеры"/>
                  Тренеры
                </NavLink>
                <NavLink
                    to="/rating"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active k_header_button_ratings"
                          : "header__btn k_header_button_ratings";
                    }}
                >
                  <img className="header__btn-img" src={rate_menu_icon} alt="рейтинг"/>
                  Рейтинг
                </NavLink>
              </div>
            </div>
            <div className="header__container">
              <button className="header__id k_load">
                <div className="header__id-texts">
                  <p className="header__id-text">ID</p>
                  <p className="header__id-text header__id-text_type_profile"></p>
                </div>
                <div className='header__id-no-main'>
                  <div
                      className="trainer_info__legend trainer_info_l_fix k_info_premium_only_text"
                      data-premium="false"
                      style={{'display': 'none'}}
                  >Premium
                  </div>
                  <div className='header__id-img'>
                    <img className='k_main_photo' src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                         alt="логотип"/>
                  </div>
                </div>
              </button>
              <a href="/profile" className="header__btn-setting header__btn-link" style={{'display': 'none'}}></a>
              <button type="button" className="header__btn-link top_menu_exit"></button>
            </div>
          </div>
        </header>
    );
  }else {
    return (
        <header>
          <div className="header container">
            <div className="header__container-btn">
              <NavLink to="/" onClick={ResetPage} className="header__logo-link">
                <img className="header__img" src={img} alt="логотип"/>
              </NavLink>
              <div className="header__btns">
                <NavLink
                    to="/"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active"
                          : "header__btn";
                    }}>
                  <img className="header__btn-img" src={pokemon} alt="логотип"/>
                  Покемоны
                </NavLink>
                <NavLink
                    to="/trainers"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active k_header_button_trainers"
                          : "header__btn k_header_button_trainers";
                    }}>
                  <img className="header__btn-img" src={coaches} alt="тренеры"/>
                  Тренеры
                </NavLink>
                <NavLink
                    to="/rating"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "header__btn header__btn_active k_header_button_ratings"
                          : "header__btn k_header_button_ratings";
                    }}
                >
                  <img className="header__btn-img" src={rate_menu_icon} alt="рейтинг"/>
                  Рейтинг
                </NavLink>
              </div>
            </div>
            <div className="header__container">
              <button className="header__id k_load">
                <div className="header__id-texts">
                  <p className="header__id-text">ID</p>
                  <p className="header__id-text header__id-text_type_profile"></p>
                </div>
                <div className='header__id-no-main'>
                  <div
                      className="trainer_info__legend trainer_info_l_fix k_info_premium_only_text"
                      data-premium="false"
                      style={{'display': 'none'}}
                  >Premium
                  </div>
                  <div className='header__id-img'>
                    <img className='k_main_photo' src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                         alt="логотип"/>
                  </div>
                </div>
              </button>
              <a href="/profile" className="header__btn-setting header__btn-link" style={{'display': 'none'}}></a>
              <button type="button" className="header__btn-link top_menu_exit"></button>
            </div>
          </div>
          <nav className="mobile">
            <ul className="mobile__list">
              <li className="mobile__item">
                <NavLink
                    to="/"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "mobile__link mobile__link_active"
                          : "mobile__link";
                    }}
                >
                  <div className="mobile__icon mobile__icon_1"></div>
                  Покемоны
                </NavLink>
              </li>
              <li className="mobile__item">
                <NavLink
                    to="/trainers"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "mobile__link mobile__link_active"
                          : "mobile__link";
                    }}
                >
                  <div className="mobile__icon mobile__icon_2"></div>
                  Тренеры
                </NavLink>
              </li>
              <li className="mobile__item">
                <NavLink
                    to="/rating"
                    onClick={ResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "mobile__link mobile__link_active"
                          : "mobile__link";
                    }}
                >
                  <div className="mobile__icon mobile__icon_3"></div>
                  Рейтинг
                </NavLink>
              </li>
              <li className="mobile__item">
                <NavLink
                    onClick={FixResetCoach}
                    className={({isActive}) => {
                      return isActive
                          ? "mobile__link mobile__link_active header__id k_load"
                          : "mobile__link header__id k_load";
                    }}
                >
                  <div className='header__id-img mobile__icon_avatar'>
                    <div
                        className="trainer_info__legend trainer_info_l_fix"
                        data-premium="false"
                        style={{'display': 'none'}}
                    ></div>
                    <div className='header__id-img-fix'>
                      <img className='k_main_photo' src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                           alt="логотип"/>
                    </div>
                  </div>
                  Профиль
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
    );
  }
}

export default Header;
