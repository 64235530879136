import arrowLeft from "../../images/arrow-left.svg";
import { Link } from "react-router-dom";

import "./BackButton.css";

function BackButton({ link }) {
  return (
    <>
      <Link to={link} className="back-button">
        <img
          className="back-button__icon"
          src={arrowLeft}
          alt="вернуться назад"
        />
        <span className="back-button__text">Назад</span>
      </Link>
    </>
  );
}

export default BackButton;
