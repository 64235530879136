import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./PokemonsGalereya.css"

import React, { Fragment } from "react";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

function PokemonsGalereya() {
    $('head').append('<style>::-webkit-scrollbar-thumb {visibility: visible;background: #ed6f2d;}  ::-webkit-scrollbar {width: 15px !important;visibility: visible;}</style>');

    function render_pokemons_png(page){
        var page_limit = 100;

        var start = ((+page - 1) * page_limit) + 1;
        var end = ((+page) * page_limit) + 1;

        $('.gallery').html('');

        if (page === 'all'){
            start = 1;
            end = 1009;
        }

        for (var i = start; i < end; i++){
            if (i > 1008){
                return false;
            }
            $('.gallery').append('<li><a href="https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(''+i).padStart(3, '0')+'.png" rel="lightbox[billeder]" title="" target="blank"><img src="https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(''+i).padStart(3, '0')+'.png" alt="Загрузка..."></img></a><span class="k_id_image">'+i+'</span></li>')
        }
    }

    setTimeout(function (){
        const urlParams = new URLSearchParams(window.location.search);
        var page = urlParams.get('page');

        if (page === '' || !page){
            page = 1;
        }

        $('#page_nav a').each(function (){
            if (+$(this).html() === +page){
                $(this).after(page);
                $(this).remove();
            }
        })

        render_pokemons_png(page);
    }, 500);

  return (
    <>
      <div className="gradient"></div>
      <main className="content container list_trainers_pokemons_page" style={{padding: "0", zIndex: 1}}>
          <center><h1 className="pokemon__title">Картинки покемонов в png</h1></center>
          <span id="breadcrumb_nav"></span>
          <div id="container">
              <ul className="gallery"></ul>
          </div>
          <br />
          <div id="page_nav">
              Страница: <a href="?dir=&amp;page=1">1</a> | <a href="?dir=&amp;page=2">2</a> | <a href="?dir=&amp;page=3">3</a> | <a href="?dir=&amp;page=4">4</a> | <a href="?dir=&amp;page=5">5</a> | <a href="?dir=&amp;page=6">6</a> | <a href="?dir=&amp;page=7">7</a> | <a href="?dir=&amp;page=8">8</a> | <a href="?dir=&amp;page=9">9</a> | <a href="?dir=&amp;page=10">10</a> | <a href="?dir=&amp;page=11">11</a> | <a href="?dir=&amp;page=all">все</a> <a href="albums/101.png" rel="lightbox[billeder]" className="hidden" title=""></a>
          </div>
          <br />
          <hr id="bottom"></hr>
          <br /><br />

          <div id="ext-shopintab-box">
              <div id="ext-shopintab-main-button"></div>
              <div id="ext-shopintab-main-tooltip"></div>
          </div>
      </main>
    </>
  );
}

export default PokemonsGalereya;
