import "./EmptyList.css";

function EmptyList({ image, title, subtitle }) {
  return (
    <>
      <div className="empty" style={{'display': 'none'}}>
        <img className="empty__img" src={image} alt="картинка" />
        <h2 className="empty__title">{title}</h2>
        <p className="empty__subtitle">{subtitle}</p>
      </div>
    </>
  );
}

export default EmptyList;
