import { React } from "react";
import site_config from "../kenshi_config/kenshi_config";

const ComponentMobile = (props) => {

    if (props.k_type === 'defolt'){
    return (
        <div className="payment__mobile-block">
            <button onClick={props.onClick} className={'payment__info-button-reverse-new'}></button>
            <div className="payment__info-mobile">
                <p className="payment__info-text">{props.k_price} ₽</p>
            </div>
            <div className={'payment__subblock-mobile'}>
                <p className="payment__subtitle-top">Магазин</p>
                <span className="payment__shopname"><a href={'https://'+site_config.main_url_react}>{site_config.main_url_react}</a></span>
                <p className="payment__subtitle-top">Номер карты</p>
                <span className="payment__span">{props.k_cart}</span>
                <p className="payment__subtitle-top">Код отправлен на номер</p>
                <span className="payment__span">*01-99</span>
            </div>
            <button onClick={props.onClick} className="payment__submit-button-mobilevers" type='button'>Ок</button>
        </div>
    )
    }else{
        return (
            <div className="payment__mobile-block">
                <button onClick={props.onClick} className={'payment__info-button-reverse-new'}></button>
                <div className="payment__info-mobile">
                    <p className="payment__info-text">{props.k_price} ₽</p>
                </div>
                <div className={'payment__subblock-mobile'}>
                    <p className="payment__subtitle-top">Магазин</p>
                    <span className="payment__shopname"><a href={'https://'+site_config.main_url_react}>{site_config.main_url_react}</a></span>
                    <p className="payment__subtitle-top">Номер заказа</p>
                    <span className="payment__span">pb:3112220001</span>
                    <p className="payment__subtitle-top">Описание</p>
                    <span className="payment__span k_info_pr">Аватар Тренера № {props.k_id}</span>
                </div>
                <button onClick={props.onClick} className="payment__submit-button-mobilevers" type='button'>Ок</button>
            </div>
        )
    }
}

export default ComponentMobile;