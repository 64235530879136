import React from "react";

function Checkbox({element, hidden, counterSelected, name}) {
    return (
        <div>
            {/*Форма с радиокнопками*/}
            <form
                action="#"
                method="post"
                className="filter__form"
                onChange={counterSelected}
            >
                <p className={"popup__subtitle"}>{name}</p>
                <fieldset className="filter__fieldset">
                    <label className="filter__label">
                        <input
                            type="checkbox"
                            className="filter__checkbox"
                            name="attack"
                            value={1}
                        />
                        <span className="filter__span">1</span>
                    </label>
                    <label className="filter__label">
                        <input
                            type="checkbox"
                            className="filter__checkbox"
                            name="attack"
                            value={2}
                        />
                        <span className="filter__span">2</span>
                    </label>
                    <label className="filter__label">
                        <input
                            type="checkbox"
                            className="filter__checkbox"
                            name="attack"
                            value={3}
                        />
                        <span className="filter__span">3</span>
                    </label>
                    <label className="filter__label">
                        <input
                            type="checkbox"
                            className="filter__checkbox"
                            name="attack"
                            value={4}
                        />
                        <span className="filter__span">4</span>
                    </label>
                    <label className="filter__label">
                        <input
                            type="checkbox"
                            className="filter__checkbox"
                            name="attack"
                            value={5}
                        />
                        <span className="filter__span">5{element}</span>
                    </label>
                </fieldset>
                <div className='filter__pokemon' data-hidden={hidden}>
                    <div className="popup-wrap">
                        Показать в нокауте
                        <label className="popup__label">
                            <input type="radio" className="popup__switch k_pokemon_f_alive" value="k_pokemon_f_alive" name="switch11"/>
                            <span className="popup__fake"></span>
                        </label>
                    </div>
                    <div className="popup-wrap">
                        Только в покеболе
                        <label className="popup__label">
                            <input type="radio" className="popup__switch k_pokemon_f_has_pokeboll" value="k_pokemon_f_has_pokeboll" name="switch11"/>
                            <span className="popup__fake"></span>
                        </label>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default Checkbox;
