import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import "./Header.css";
import img from "../../images/header-img.svg";
import pokemon from "../../images/icons/ball.png";
import coaches from "../../images/icons/hat.png";
import shops from "../../images/icons/coin.png";
import id from "../../images/trainers/11.png";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

function HeaderNo() {
  function ResetCoach(){
    Cookies.set('sort_trainers', 'asc_date', { expires: 365 });
    Cookies.set('sort_pokemons', 'asc_date', { expires: 365 });
    Cookies.set('page_pokemons', '1', { expires: 365 });
    Cookies.set('search_pokemons', '', { expires: 365 });
    Cookies.set('check_filters', '', { expires: 365 });

    $('.pokemon__filter-counter').attr('hidden', 'true');
    if ($('.radio-mobile')[0]) {
      setTimeout(function (){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
          $($('.radio-mobile')[0]).attr('checked', 'checked');
        }else{
          $($('.popup__form input.radio')[0]).attr('checked', 'checked');
        }
      }, 500);
    }
  };

  return (
      <header>
        <div className="header container">
          <div className="header__container-btn">
            <Link to="/login" className="header__logo-link">
              <img className="header__img" src={img} alt="логотип" />
            </Link>
            <div className="header__btns">
              <NavLink
                  to="/login"
                  onClick={ResetCoach}
                  className={({ isActive }) => {
                    return isActive
                        ? "header__btn header__btn_active"
                        : "header__btn";
                  }}
              >
                <img className="header__btn-img" src={pokemon} alt="логотип" />
                Покемоны
              </NavLink>
              <NavLink
                  to="/login"
                  onClick={ResetCoach}
                  className={({ isActive }) => {
                    return isActive
                        ? "header__btn header__btn_active"
                        : "header__btn";
                  }}
              >
                <img className="header__btn-img" src={coaches} alt="логотип" />
                Тренеры
              </NavLink>
              <NavLink
                  to="/login"
                  className={({ isActive }) => {
                    return isActive
                        ? "header__btn header__btn_active"
                        : "header__btn";
                  }}
              >
                <img className="header__btn-img" src={shops} alt="логотип" />
                Магазин
              </NavLink>
            </div>
          </div>
          <div className="header__container">
            <button className="header__id">
              <div className="header__id-texts">
                <p className="header__id-text">ID</p>
                <p className="header__id-text header__id-text_type_profile"></p>
              </div>
              <div className='header__id-img'>
                <img className='k_main_photo' src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="логотип" />
              </div>

            </button>
            <a href="/login" className="header__btn-setting header__btn-link"></a>
            <button type="button" className="header__btn-link top_menu_exit"></button>
          </div>
        </div>
        <nav className="mobile">
          <ul className="mobile__list">
            <li className="mobile__item">
              <NavLink
                  to="/login"
                  onClick={ResetCoach}
                  className={({ isActive }) => {
                    return isActive
                        ? "mobile__link mobile__link_active"
                        : "mobile__link";
                  }}
              >
                <div className="mobile__icon mobile__icon_1"></div>
                Покемоны
              </NavLink>
            </li>
            <li className="mobile__item">
              <NavLink
                  to="/login"
                  onClick={ResetCoach}
                  className={({ isActive }) => {
                    return isActive
                        ? "mobile__link mobile__link_active"
                        : "mobile__link";
                  }}
              >
                <div className="mobile__icon mobile__icon_2"></div>
                Тренеры
              </NavLink>
            </li>
            <li className="mobile__item">
              <NavLink
                  to="/login"
                  className={({ isActive }) => {
                    return isActive
                        ? "mobile__link mobile__link_active"
                        : "mobile__link";
                  }}
              >
                <div className="mobile__icon mobile__icon_3"></div>
                Магазин
              </NavLink>
            </li>
            <li className="mobile__item">
              <NavLink
                  className={({ isActive }) => {
                    return isActive
                        ? "mobile__link mobile__link_active header__id"
                        : "mobile__link header__id";
                  }}
              >
                <div className='header__id-img mobile__icon_avatar'>
                  <img className='k_main_photo' src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="логотип" />
                </div>
                Профиль
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
  );
}

export default HeaderNo;
