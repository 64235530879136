import React, {useEffect} from "react";
import Level from "../../Level/Level";
import Bol from "./Bol/Bol";
import EmptyList from "../../EmptyList/EmptyList";
import emptyTrainerList from "../../../images/emptyTrainerList.png";
import "./TrainerList.css";
import Copy from "../../Copy/Copy";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import $ from "jquery";
import none_trainer from '../../../images/none_trainer.png';
import KenshiConfig from "../../kenshi_config/kenshi_config";
import none_pokemon from "../../../images/none_pokemon.png";

Cookies.set('sort_trainers', 'asc_date', { expires: 365 });
Cookies.set('page_pokemons', '1', { expires: 365 });
Cookies.set('search_pokemons', '', { expires: 365 });
Cookies.set('check_filters', '1', { expires: 365 });
var data = {};

const start_initial_trainer_list = false;

const TrainerList = (select) => {
    var [HistoryListOpen, SetHistoryListOpen] = React.useState(select);
    var [dataz, setDataz] = React.useState("");
    var body = $('body');

    function handleClick(new_val) {
        var trainerList = [];
        setDataz(new_val);
        SetHistoryListOpen(false);
    }

    function handleKenshi() {
        SetHistoryListOpen(trainerList);
        setDataz(Cookies.get('page_pokemons'));
    }

    body.off('change', '.k_coach_sort_pc input, .k_coach_sort_mobile input');
    body.on('change', '.k_coach_sort_pc input, .k_coach_sort_mobile input', function(){
        Cookies.set('sort_trainers', $(this).val(), { expires: 365 });
        Cookies.set('page_pokemons', 1, { expires: 365 });

        handleClick($(this).val()+'sort_trainers');
    });

    body.off('click', '.parent__mainblock_load_more_button');
    body.on('click', '.parent__mainblock_load_more_button', function(){
        Cookies.set('page_pokemons', (+Cookies.get('page_pokemons') + 1), { expires: 365 });

        handleKenshi();
    });
    var search_int = '';
    body.off('change keyup', '#search-input');
    body.on('change keyup', '#search-input', function(){
        clearTimeout(search_int)
        search_int = setTimeout(function (){
            Cookies.set('page_pokemons', 1, { expires: 365 });
            Cookies.set('search_pokemons', $('#search-input').val(), { expires: 365 });

            handleClick($('#search-input').val()+'start_search');
            clearTimeout(search_int);
        }, 1200)
    });

    body.off('click', '.form-search img.form__img-close');
    body.on('click', '.form-search img.form__img-close', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });
        Cookies.set('search_pokemons', '', { expires: 365 });

        handleClick('end_search');
    });

    body.off('click', '.filter_power input');
    body.on('click', '.filter_power input', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });

        var i_attack = 1;
        var data_attack = '';
        $('.filter_power input').each(function (){
            if ($(this).prop('checked')){
                data_attack += i_attack+',';
            }
            i_attack++;
        });

        handleClick(data_attack+'edit_attac_search');
    });

    body.off('click', '.filter__button-reset_show, .filter__button-reset');
    body.on('click', '.filter__button-reset_show, .filter__button-reset', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });

        handleClick('reset_module_search');
    });

    Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

    data.sort = Cookies.get('sort_trainers');
    data.page = Cookies.get('page_pokemons');

    if (Cookies.get('check_filters') == '1'){
        var i_attack = 1;
        var menu_badge_val = 0;
        data.level = '';
        $('.filter_power input').each(function (){
            if (i_attack === 8){
                i_attack = 1;
            }

            if ($(this).prop('checked')){
                data.level += i_attack+',';
                menu_badge_val++;
            }
            i_attack++;
        });

        if (data.level !== ''){
            if (data.level) {
                data.level = data.level.slice(0, -1);
            }
        }else{
            delete data.level;
        }

        if (Cookies.get('search_pokemons') != '' && Cookies.get('search_pokemons') != undefined && Cookies.get('search_pokemons') != 'undefined'){
            data.name = Cookies.get('search_pokemons');
        }else{
            delete data.name;
        }
    }else{
        Cookies.set('check_filters', '1', { expires: 365 });
    }

    var trainerList = [];

    if (HistoryListOpen.length >= 1){
        trainerList = HistoryListOpen;
    }

    var title_none= 'Никого не найдено'
    var title_sub= 'Попробуйте изменить параметры запроса'
    var image_empty = none_trainer

    $('.empty__title').html(title_none);
    $('p.empty__subtitle').html(title_sub);
    $('img.empty__img').attr('src', image_empty);

    var next_page = 0;

    const counter_trainers = React.useRef(0);
    counter_trainers.current++;

    if (counter_trainers.current > 1) {
        $.ajax({
            url: site_config.new_api + '/trainers?' + $.param(data),
            type: 'GET',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            async: false,
            success: function (data) {
                if (data.data === "Тренеры не найдены") {
                    title_none = 'Никого не найдено'
                    title_sub = 'Попробуйте изменить параметры запроса'
                    image_empty = none_trainer
                    setTimeout(function (){
                        $('.empty').fadeIn();
                    }, 500);

                    $('.parent__mainblock_load_more').css('display', 'none');
                    return false;
                }

                next_page = data.next_page;
                data = data.data;

                var type = 'trainers';
                if (data.length > 100) {
                    return false;
                }

                var data_lenght = data.length;

                if (data_lenght <= 0) {
                    title_none = 'Никого не найдено'
                    title_sub = 'Попробуйте изменить параметры запроса'
                    image_empty = none_trainer
                }

                for (var k_i = 0; k_i < data_lenght; k_i++) {
                    var iconPrime = false;
                    if (Math.round(data[k_i].level) >= 5) {
                        iconPrime = true;
                    }

                    var trainer_premium = false;
                    if (data[k_i].is_premium) {
                        trainer_premium = true;
                    }

                    trainerList.push({
                        img: 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/' + ('' + data[k_i].avatar_id).padStart(2, '0') + '.png',
                        name: data[k_i].trainer_name,
                        iconPrime: iconPrime,
                        trainer_premium: trainer_premium,
                        level: Math.round(data[k_i].level),
                        bolActive: data[k_i].pokemons_in_pokeballs.length,
                        copy: data[k_i].id,
                        link: "/trainer/" + data[k_i].id,
                    });
                }
                if (type === 'trainers') {
                    if (next_page === false) {
                        setTimeout(function () {
                            $('.parent__mainblock_load_more').css('display', 'none');
                        }, 200);
                    } else {
                        setTimeout(function () {
                            $('.parent__mainblock_load_more').css('display', 'flex');
                        }, 200);
                    }
                }

            }, error: function () {
                title_none = 'Не удалось загрузить данные'
                title_sub = 'Попробуйте зайти через несколько минут или обновите страницу'
                image_empty = emptyTrainerList

                $('.empty__title').html(title_none);
                $('p.empty__subtitle').html(title_sub);
                $('img.empty__img').attr('src', image_empty);
                $('.empty').fadeIn();
            }
        });
    }

    return (
        <section className="trainers container">
            {trainerList.length !== 0 ? (
                <div>
                    <ul className="trainers__list">
                        {trainerList.map((item, index) => {
                            return (
                                <div data-href={item.link} className="trainers__link" key={index}>
                                    <li className="trainers__item">
                                        <div className="trainers__image">
                                            <img
                                                src={item.img}
                                                className="trainers__img"
                                                alt={item.name}
                                            />
                                        </div>
                                        <div className="trainers__box">
                                            <div className="trainers__title">
                                                <h2 className="trainers__title-text">{item.name}</h2>
                                                <div
                                                    className="trainers__icon-cup"
                                                    data-prime={item.iconPrime}
                                                ></div>
                                                <div
                                                    className="trainer_info__legend"
                                                    data-premium={item.trainer_premium}
                                                    style={{'display': 'none'}}
                                                ></div>

                                            </div>
                                            <div className="trainers__stat">
                                                <Level level={item.level} name="Уровень"/>
                                                <Bol active={item.bolActive}/>
                                            </div>
                                            <Copy copyCount={item.copy} hidden={true}/>
                                        </div>

                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                    <div className="parent__mainblock_load_more" style={{display: "none"}}>
                        <button className="parent__mainblock_load_more_button">Загрузить еще</button>
                    </div>
                </div>
            ) : (
                <EmptyList
                    image={image_empty}
                    title={title_none}
                    subtitle={title_sub}
                />
            )}
        </section>
    );
}

export default TrainerList;
