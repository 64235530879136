import "./TitleSingle.css";
import imageBall from "../../images/icons/ball.png";
import imageCoin from "../../images/icons/gold_ball.png";
import imageScull from "../../images/skull.svg";
import imageCup from "../../images/icons/trophy.png";
import img_premium from "../../images/fire_alt_16.png";

function TitleSingle({
  subtitle,
  title,
  iconBall,
  kill,
  iconCoin,
  iconScull,
  iconCup,
  data,
}) {
  return (
    <div className="title-single">
      <p className="title-single__subtitle">{subtitle}</p>
      <div className="title-single__box">
        <h2 className="title-single__title k_cart_trainer_name">{title}</h2>
        <div
          className="title-single__icon"
          data-icon={iconBall}
          data-kill={kill}
        >
          <img
            src={imageBall}
            className="title-single__img"
            alt="иконки заголовка"
          />
        </div>
        <div
          className="title-single__icon"
          data-icon={iconCoin}
          data-kill={kill}
        >
          <img
            src={imageCoin}
            className="title-single__img"
            alt="иконки заголовка"
          />
        </div>
        <div
          className="title-single__icon"
          data-icon={iconScull}
          data-kill={kill}
        >
          <img
            src={imageScull}
            className="title-single__img"
            alt="иконки заголовка"
          />
        </div>
        <div
          className="title-single__icon title-single__icon-bg k_cart_trainer_cup"
          data-icon={iconCup}
          data-kill={kill}
          style={{display:'none'}}
        >
          <img
            src={imageCup}
            className="title-single__img"
            alt="иконки заголовка"
            data-atr={data}
          />
        </div>
        <div
            className="title-single__icon title-single__icon-bg k_cart_trainer_premium"
            style={{display:'none'}}>
          <img
              src={img_premium}
              className="title-single__img"
              alt="иконки заголовка"
              data-atr={data}
          />
        </div>
      </div>
    </div>
  );
}

export default TitleSingle;
