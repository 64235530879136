import Header from "../Header/Header";
import './Tracer.css';
import Footer from "../Footer/Footer";
import $ from "jquery";
import site_config from "../kenshi_config/kenshi_config";
import Cookies from "js-cookie";

$('body').on('click', '.k_tracer_one', function (){
    if($(this).find('.k_tracer_one_right_open').hasClass('active')){
        $(this).find('.k_tracer_one_right_open').removeClass('active');

        $(this).find('.k_tracer_one_bottom').slideUp();
    }else{
        $(this).find('.k_tracer_one_right_open').addClass('active');

        $(this).find('.k_tracer_one_bottom').slideDown();
    }
})

$('body').on('change keyup', '.k_search_trace', function (){
    var search_q = $(this).val();
    $('.k_tracer_one').each(function (){
        if ($(this).find('.k_tracer_one_right_title').html().indexOf(search_q) >= 0){
            $(this).css('display', 'none');
        }else{
            $(this).css('display', 'block');
        }
    })
})

function Tracer() {
    var device_id = Cookies.get('device_id', false);

    if (!device_id){
        window.location.href = '/';
    }else{
        $.ajax({
            url: site_config.new_api + '/technical_routes/get_trace?device_id='+device_id,
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                console.log(data);
                data = data.traces;

                for (var i = data.length - 1; i>0; i--){
                    var info_trace = data[i];

                    var element = $('.k_tracer_one_default').clone();
                    element.removeClass('k_tracer_one_default');
                    element.addClass('k_tracer_one');
                    element.css('display', 'block')

                    element.find('.k_tracer_one_left_id').html('#'+i);
                    element.find('.k_tracer_one_left_status').html(info_trace.status);
                    element.find('.k_tracer_one_left').addClass('stat_'+info_trace.status);
                    element.find('.k_tracer_one_right_date').html(info_trace.date);
                    element.find('.k_tracer_one_right_title').html(info_trace.title);
                    element.find('.k_tracer_one_right_speed').html(info_trace.ms+'ms');
                    element.find('.k_tracer_one_bottom_content').html(info_trace.body);

                    $('.k_tracer_wrappers').append(element)
                }
            }
        });
    }

  return (
    <>
      <Header />
      <main className="content">
        <div className="gradient"></div>
          <h2 className="k_h2">Журнал запросов</h2>
          <div className="k_tracer_wrapper">
              <div className="k_tracer_wrapper_search">
                  <input className="k_search_trace" type="text" placeholder="Поиск" />
              </div>
              <div className="k_tracer_wrappers">
                  <div className="k_tracer_one_default" style={{'display': 'none'}}>
                      <div className="k_tracer_one_ttop">
                          <div className="k_tracer_one_left">
                              <div className="k_tracer_one_left_id"></div>
                              <div className="k_tracer_one_left_status"></div>
                          </div>
                          <div className="k_tracer_one_right">
                              <div className="k_tracer_one_right_date"></div>
                              <div className="k_tracer_one_right_title"></div>
                              <div className="k_tracer_one_right_speed"></div>
                              <div className="k_tracer_one_right_open">></div>
                          </div>
                      </div>
                      <div className="k_tracer_one_bottom">
                          <div className="k_tracer_one_bottom_content"></div>
                      </div>
                  </div>
              </div>
          </div>
      </main>
      <Footer />
    </>
  );
}

export default Tracer;
