import React from "react";
import TrainerPokemon from "../../Filter/TrainerPokemon/TrainerPokemon";
import PopupSort from "../../PopupSort/PopupSort";
import Search from "../../Filter/Search/Search";
import search from "../../../images/search.svg";
import PopupFilter from "../../PopupFilter/PopupFilter";
import "../../Pokemon/Pokemon.css";
import "./TrainerFilter.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import $ from "jquery";

function Trainer() {
  // открытие - закрытие попапа
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupOpenFilter, setPopupOpenFilter] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [state, setState] = React.useState(false);
  const [stateInput, setStateInput] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const [stateSticky, setStateSticky] = React.useState(false);
  const [stateBtnFilter, setStateBtnFilter] = React.useState(true);

  //Для кнопки "Сбросить" в фильтре
  const [hiddenReset, setHiddenReset] = React.useState(false);

  const body = document.body;

  const counter = window.innerWidth > 992;
  const formBtnClassName = state ? "form_type_btn" : "";
  const pokemonClassName = "pokemon k_sticky_header";
  const formInputClassName = stateInput ? "form_active" : "";
  const popupClassName = popupOpen ? "pokemon__sort_active" : "";

  React.useEffect(() => {
    setStateSticky(false);
  }, []);

  window.addEventListener("scroll", function () {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 0 && !counter) {
      setStateSticky(true);
    } else {
      setStateSticky(false);
    }
  });

  const options = [
    ["Сначала новые", "asc_date", "selected"],
    ["По убыванию уровня", "desc_level", ""],
    ["По возрастанию уровня", "asc_level", ""],
    ["По алфавиту", "asc", ""],
  ];

  function handleclosePopup(e) {
    const isBtnSort = e.target.closest(".pokemon__sort");
    const isPopup = e.target.closest(".popup-sort_opened");
    const isPopupMobile = e.target.closest(".popup-sort-mobile_opened");
    const isBtnFilter = e.target.closest(".pokemon__filter-btn");
    const isPopupFilter = e.target.closest(".popup-filter_opened");
    const isPopupMobileFilter = e.target.closest(".popup-filter-mobile_opened");
    if (
        !isPopup &&
        !isBtnSort &&
        !isPopupMobile &&
        !isBtnFilter &&
        !isPopupFilter &&
        !isPopupMobileFilter
    ) {
      closePopup();
    }
  }

  document.addEventListener("click", handleclosePopup);

  function closePopup() {
    setPopupOpen(false);
    setPopupOpenFilter(false);
    //setIsOpen(false);
    //counterSelectedDelete();
  }
  function formOpen() {
    setState(true);
    setDisabledButton(false);
  }
  function closeForm() {
    setState(false);
    setDisabledButton(true);
  }

  function HandleInput() {
    setStateInput(true);
  }

  const [screenWidth, setWidth] = React.useState();

  const findScreenWidth = () => {
    setWidth(window.screen.width);
    if (window.screen.width >= 992) {
      setState(false);
    }
  };

  //Размер экрана
  React.useEffect(() => {
    window.addEventListener("resize", findScreenWidth);
    return () => {
      window.removeEventListener("resize", findScreenWidth);
    };
  }, []);

  //Подсчет выбранных чекбоксов
  const counterSelected = () => {
    setStateBtnFilter(false);
    let out = document.querySelector(".pokemon__filter-counter");
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.addEventListener("change", () => {
        out.textContent =  $('.pokemon__container-form input:checked').length;
        if(out.textContent === 0) {
          setStateBtnFilter(true);
          setHiddenReset(false);
          console.log(out)
        }
      });
    });
    setHiddenReset(true);
  };

  //Сброс выбранных чекбоксов (всех)
  const counterSelectedDelete = () => {
    setStateBtnFilter(true);
    setHiddenReset(false);
    document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  const stylev2 = state
      ? "class-invis"
      : `pokemon__sort pokemon__sort_mobile_sticky ${popupClassName}`;

  //Выключение прокрутки экрана
  function disabledScroll() {
    //body.classList.add("disable-scroll");
  }
  function enabledScroll() {
    //body.classList.remove("disable-scroll");
  }
  popupOpenFilter || popupOpen ? disabledScroll() : enabledScroll();

  return (
      <>
        <section className={pokemonClassName}>
            <div className="pokemon__content container">

                <div className="pokemon__container">
                  <h1 className="pokemon__title">Тренеры</h1>

                  <div className='filter-wrapper'>

                    {/* Сортировка - начало  */}
                    <PopupSort
                        isOpen={popupOpen}
                        onclose={closePopup}
                        setSelectedOption={setSelectedOption}
                        options={options}
                    />

                    {popupOpen ? (
                        <div
                            type="button"
                            className={
                              popupOpen ? "pokemon__sort active" : "pokemon__sort"
                            }
                            onClick={() => setPopupOpen(false)}
                        >
                          <p className="pokemon__text">
                            {selectedOption || "Сначала новые"}
                          </p>
                          <div type="button" className="pokemon__img-close"></div>
                        </div>
                    ) : (
                        <div
                            type="button"
                            className={
                              popupOpen ? "pokemon__sort active" : "pokemon__sort"
                            }
                            onClick={() => setPopupOpen(true)}
                        >
                          <p className="pokemon__text">
                            {selectedOption || "Сначала новые"}
                          </p>
                          <div type="button" className="pokemon__img"></div>
                        </div>
                    )}
                  {/* Сортировка - конец  */}

                  <div className="pokemon__container-form active">
                    {/* Поле поиска - начало  */}
                    <Search
                        isOpenFilter={popupOpen}
                        setPopupOpenFilter={setPopupOpenFilter}
                    />

                    {/* Поле поиска - конец  */}

                    {/* Фильтр - начало  */}
                    <TrainerPokemon
                        isOpenFilter={popupOpenFilter}
                        onclose={closePopup}
                        counterSelected={counterSelected} counterSelectedDelete={counterSelectedDelete}
                        setStateBtnFilter={setStateBtnFilter}
                        hiddenReset={hiddenReset}
                        setHiddenReset={setHiddenReset}
                        hidden={true}
                    />

                    {popupOpenFilter ? (
                        <div
                            type="button"
                            className="pokemon__filter-btn pokemon__filter-btn_active"
                            onClick={closePopup}
                        >
                          <div
                              hidden={stateBtnFilter}
                              className="pokemon__filter-counter"
                          ></div>
                        </div>
                    ) : (
                        <div
                            hidden={!disabledButton}
                            type="button"
                            className="pokemon__filter-btn"
                            onClick={() => setPopupOpenFilter(true)}
                        >
                          <div
                              hidden={stateBtnFilter}
                              className="pokemon__filter-counter"
                          ></div>
                        </div>
                    )}

                    <div
                        hidden={disabledButton}
                        type="button"
                        className="pokemon__filter-close-form"
                        onClick={closeForm}
                    ></div>

                    {/* Фильтр - конец  */}
                </div>
                </div>
            </div>
        </div>
      </section>
  </>
  );
}

export default Trainer;