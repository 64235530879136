import React, { useState } from "react";
import logo from "../../../images/logo-studio.svg";
import VK from "../../../images/icons/VK_Logo.svg";
import telegram from "../../../images/telegram.svg";
import ios from "../../../images/ios.svg";
//1
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import "../Auth.css";
import "./Login.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import {MainFunc} from "../../kenshi_config/kenshi_functions"
import $ from "jquery";
import kenshi_config from "../../kenshi_config/kenshi_config";
import Footer from "../../Footer/Footer";
import KenshiConfig from "../../kenshi_config/kenshi_config";

window.onTelegramAuth = function(user) {
    user.api_key = 'none('
    user.hash_tg = user.hash
    $.ajax({
        url: site_config.new_api + '/technical_routes/auth_control',
        type: 'POST',
        dataType: 'json',
        data: user,
        headers: {'device_id': Cookies.get('device_id')},
        success: function (data) {


            if (data.status === 'reg' || Cookies.get('telegram_add') === 'true' ){
                Cookies.set('telegram_hash', user.id, { expires: 365 });
                Cookies.set('telegram_name', user.username, { expires: 365 });
                window.location.href = '/registration-telegram';
            }else{
                if (data.hash) {
                    Cookies.set('session_id', data.hash, { expires: 365 });
                    Cookies.set('user_id', data.id, { expires: 365 });
                    Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });

                    Cookies.set('login', data.login, { expires: 365 });
                    Cookies.set('vk_auth_id', data.vk_auth_id, { expires: 365 });
                    window.location.href = '/';
                }
            }
        }
    });
}

$('body').on('click', '.k_show_500', function(){
    $.ajax({
        url: site_config.new_api + '/debug_sentry',
        type: 'GET',
        dataType: 'json',
        success: function(data){
            window.location.href = '/500';
        }, error: function (){
            window.location.href = '/500';
        }
    });
});

$('body').on('change', '.k_form_f_email', function (){
    MainFunc.k_check_field('.k_form_f_email', 'email');
});

$('body').on('change', '.k_form_f_pass', function (){
    if (!MainFunc.k_check_field('.k_form_f_pass', 'text')){
        $('.k_main_error_text').text('Введите пароль');
    }
});

$('body').on('click', '.k_form_send_auth', function(){

    let k_error = 0;

    var email_check = MainFunc.k_check_field('.k_form_f_email', 'email');
    var pass_check = MainFunc.k_check_field('.k_form_f_pass', 'text');
    if (!email_check || !pass_check) {
        k_error = 1;
        //$('.k_main_error_text').fadeOut();
    }

    if ( k_error === 0){
        $.ajax({
            url: site_config.new_api + '/technical_routes/auth_control',
            type: 'POST',
            dataType: 'json',
            data: {email: $('.k_form_f_email').val(), pass_1: $('.k_form_f_pass').val(), action: 'auth_trainer'},
            success: function(data){
                Cookies.set('session_id', data.trainer_token, { expires: 365 });
                Cookies.set('user_id', data.id, { expires: 365 });
                Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });
                window.location.href = '/';
            },
            error: function (data){
                data = JSON.parse(data.responseText)

                $('.k_main_error_text').text(data.message);
                $('.k_form_f_email').addClass('k_f_error');
                $('.k_form_f_pass').addClass('k_f_error');
                $('.k_main_error_text').fadeIn();
            }
        });
    }

    return false;
});

const Login = () => {
    const [passwordView, setPasswordView] = useState(false);
    const [type, setType] = useState("password");

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "onSubmit",
    });

    const onSubmit = (data) => {
        if (isValid) {
            //reset();
        }
    };

    function handleChange(event) {
        const input = document.querySelector('.auth__input-icon');
        if(event.target.value.length >= 1) {
            input.style.display = 'block';
        } else {
            input.style.display = 'none';
        }
    }

    function HandlePasswordView(e) {
        setPasswordView(!passwordView);
        if (type === "password") {
            setType("text");
        } else {
            setType("password");
        }
    }

    var url_to_google = 'https://id.vk.com/auth?uuid='+kenshi_config.tech_api_vk_secret_public_hash+'&app_id='+kenshi_config.tech_api_vk_app_id+'&response_type=silent_token&redirect_uri='+KenshiConfig['new_api']+'/technical_routes/callback_vk';

    return (
        <>
            <main className="content">
                <div className="gradient auth_gradient"></div>
                <section className="auth login">
                    <div className="login-mobile">
                        <h2 className="login-mobile__title">Битва покемонов</h2>
                        <p className="login-mobile__subtitle">QA Studio v1.05</p>
                    </div>

                    <div className="login__content">
                        <h1 className="auth__title">Битва покемонов</h1>
                        <p className="auth__text">Вход через соцсеть</p>
                        <div className="auth__socials">
                            <Link to={url_to_google} className="auth__social">
                                <img
                                    src={VK}
                                    className="auth__social-icon"
                                    alt="Иконка социальной сети"
                                />
                            </Link>
                        </div>
                        <form action="#" className="auth__form" onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                            <div className="auth__wrap">
                                <input
                                    type="email"
                                    className="auth__input k_form_f_email"
                                    placeholder=' '
                                />
                                <label className="auth__label">Почта</label>
                                <span className="auth__error" style={{display:'none'}}>Введите почту</span>
                            </div>
                            <div className="auth__wrap">
                                <input
                                    type={type}
                                    id='password'
                                    className="auth__input auth__input-icons k_form_f_pass"
                                    placeholder=' '
                                    onChange={handleChange}
                                />
                                <label className="auth__label">Пароль</label>
                                <span
                                    className={
                                        !passwordView
                                            ? "auth__input-icon"
                                            : "auth__input-icon view-pass"
                                    }
                                    onClick={HandlePasswordView}
                                ></span>
                                <span className="auth__error k_main_error_text" style={{display:'none'}}>Введите пароль</span>
                            </div>
                            <Link to="/new-pass-mail" className="auth__restore">
                                Восстановить
                            </Link>
                            <button type="submit" className="auth__button k_form_send_auth">
                                Войти
                            </button>
                            <Link to="/registration" className="auth__text auth__registr">
                                Зарегистрироваться
                            </Link>
                        </form>
                    </div>
                </section>
            </main>
            <div className="footer_no_line">
                <Footer />
            </div>
        </>
    );
};

export default Login;
