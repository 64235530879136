import React, {useState} from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../../../images/logo-studio.svg";

import "../Auth.css";
import "./NewPassword.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import {MainFunc} from "../../kenshi_config/kenshi_functions"
import $ from "jquery";
import Footer from "../../Footer/Footer";

const NewPassword = () => {
  const [toggle, setToggle] = useState(true);
  const handleChange = (event) => {
    const input = event.target;
    const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
    icon.style.display='block';
    if(input.value.length == 0) {
      icon.style.display='none';
    }
  }

  const handleClick = (event) => {
    const icon = event.target;
    const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
    if(toggle) {
      input.type = 'text';
      icon.classList.add('view-pass');
    }  else {
      input.type = 'password';
      icon.classList.remove('view-pass');
    }
    setToggle(!toggle);
  }

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });

  console.log(Cookies.get('password_re'));
  if (Cookies.get('password_re') === null || Cookies.get('password_re') === 'null'){
    window.location.href = '/login';
    return;
  }

  $('body').off('change', '.k_form_re_pass_1, .k_form_re_pass_2');
  $('body').on('change', '.k_form_re_pass_1, .k_form_re_pass_2', function (){
    MainFunc.k_check_field('.k_form_re_pass', 'pass', true)
  });
  const onSubmit = (data) => {
    let k_error = 0;

    if ( Cookies.get('password_re') ){
        $.ajax({
            url: site_config.new_api + '/trainers/re',
            type: 'POST',
            dataType: 'json',
            data: {action: 're_new', id: Cookies.get('user_re_id'), hash_tg: Cookies.get('password_re'), password: $('.k_form_re_pass_1').val(), password_re: $('.k_form_re_pass_2').val()},
            success: function(data){
                Cookies.set('password_re', 'null');
                window.location.href = '/confirmation-ok-1';
            }, error: function (data){
                data = JSON.parse(data.responseText)

                $('.auth__error_main').fadeIn();
                $('.auth__error_main').text(data.message);
            }
        });
    }
  };
  return (
    <>
      <main className="content">
        <div className="gradient auth_gradient"></div>
        <section className="auth restore auth-full">
          <div className="auth__content register__content">
            <h1 className="auth__title">Битва покемонов</h1>
            <div className="auth__mobile auth__mobile-top">
              <a href="/login" className="auth__back"></a>
              <p className="auth__title-h2">Создание нового пароля</p>
            </div>
            <p className="auth__confirmation auth__confirmation-mb">
              Придумайте новый пароль. Минимум 6 символов, 1 заглавная буква
              и 1 цифра
            </p>
            <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
              <div className="auth__wrap">
                <input
                  type="password"
                  className="auth__input auth__input-pass k_form_re_pass_1"
                  {...register("password", {
                    required: "Введите пароль",
                    minLength: {
                      value: 6,
                      message: "Новый пароль не соответствует требованиям. \n" +
                          "Минимум 6 символов, 1 заглавная буква и 1 цифра",
                    },
                  })}
                  aria-invalid={errors.password ? "true" : "false"}
                  placeholder=' '
                  onChange={handleChange}
                />
                <label className="auth__label">Новый пароль</label>
                <span
                    className="auth__input-icon"
                    onClick={handleClick}
                ></span>
                <span className="auth__error"></span>
              </div>
              <div className="auth__wrap wrapper-mb">
                <input
                  type="password"
                  className="auth__input auth__input-pass k_form_re_pass_2"
                  {...register("pass", {
                    required: "Пароли не совпадают",
                    validate: (match) => {
                      const password = getValues("password")
                      return match === password || "Пароли не совпадают"
                    },
                  })}
                  aria-invalid={errors.pass ? "true" : "false"}
                  placeholder=' '
                  onChange={handleChange}
                />
                <label className="auth__label">Повторите новый пароль</label>
                <span
                    className="auth__input-icon"
                    onClick={handleClick}
                ></span>
                <span className="auth__error auth__error_main"></span>
              </div>
              <button type="submit" className="auth__button new-pass__save k_margin_top_15">
                Сохранить пароль
              </button>
              <Link to="/login" className="auth__text auth__registr new-pass__btn">
                Войти
              </Link>
            </form>
          </div>
        </section>
      </main>
      <div className="footer_no_line">
        <Footer />
      </div>
    </>
  );
};

export default NewPassword;
