import Header from "../Header/Header";
import Shop from "./Shop/Shop";
import ShopList from "./ShopList/shopList";
import Footer from "../Footer/Footer";

function Shops() {
  return (
    <>
      <Header />
      <main className="content container">
        <div className="gradient"></div>
        <Shop />
        <ShopList />
      </main>
      <Footer />
    </>
  );
}

export default Shops;
