import React from "react";
import "./PokemonData.css";
import trainer from "../../../images/foto.svg";
import trophy from "../../../images/cup.svg";

function PokemonData(props) {
  return (
    <div className="status">
      <div className="status__about">
        <p className="status__text">ID покемона</p>
        <span className="status__counter">1001</span>
        <span className="status__copy"></span>
        <p className="status__text">Стадия</p>
        <span className="status__counter">2</span>
        <ul className="status__level-force">
          <li className="status__active"></li>
          <li className="status__active"></li>
          <li className="status__active"></li>
          <li className="status__active"></li>
          <li></li>
        </ul>
        <p className="status__text">Атака</p>
        <span className="status__counter">7</span>
        <ul className="status__level-force">
          <li className="status__active"></li>
          <li className="status__active"></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div className="status__level">
        <img className="status__foto" src={trainer} alt="trainer" />
        <div className="status__wrap">
          <div className="status__data-block-top">
            <p className="status__category">Тренер</p>
            {!props.isWinner ? (
              <p className="status__name">Каллахад</p>
            ) : (
              <>
                <p className="status__name-winner">Leone Sextus Deydhhehuhf </p>
                <img src={trophy} alt="trophy" className="status__name-span" />
              </>
            )}
          </div>
          <div className="status__data-block">
            <div
              className={
                props.isChampion ? "status__attack-champion" : "status__attack"
              }
            >{`Уровень ${props.level}`}</div>
            <p className="status__counter">1001</p>
            <span className="status__copy_positioned" />
          </div>
        </div>
        <button type="button" className="status__arrow" />
      </div>
    </div>
  );
}

export default PokemonData;
