import "./Copy.css";

function Copy({ copyCount, hidden }) {
  return (
    <div className="copy" data-hidden={hidden}>
      <p className="copy__text">{copyCount}</p>
      <button className="copy__btn"></button>
    </div>
  );
}

export default Copy;
