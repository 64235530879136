import React, {useEffect} from "react";
import none_pokemon from '../../images/none_pokemon.png'
import pokemonEmpty from "../../images/pokemon-6.jpg";
import emptyList from "../../images/pokemons/no-img.png";

import Level from "../Level/Level";
import "./CardList.css";
import Copy from "../Copy/Copy";

import EmptyList from "../EmptyList/EmptyList";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

const pokemonList1 = [];

Cookies.set('sort_pokemons', 'asc_date', { expires: 365 });
Cookies.set('page_pokemons', '1', { expires: 365 });
Cookies.set('search_pokemons', '', { expires: 365 });
Cookies.set('check_filters', '1', { expires: 365 });
var data = {};

Cookies.set('fix_react_sip', '1', { expires: 365 });


const CardList = (select) => {
    var [HistoryListOpen, SetHistoryListOpen] = React.useState(select);
    var [dataz, setDataz] = React.useState("");
    var body = $('body');

    function handleClick(new_val) {
        var pokemonList = [];
        setDataz(new_val);
        SetHistoryListOpen(false);
    }

    function handleKenshi() {
        SetHistoryListOpen(pokemonList);
        setDataz(Cookies.get('page_pokemons'));
    }

    body.off('change', '.k_coach_sort_pc input, .k_coach_sort_mobile input');
    body.on('change', '.k_coach_sort_pc input, .k_coach_sort_mobile input', function(){
        Cookies.set('sort_pokemons', $(this).val(), { expires: 365 });
        Cookies.set('page_pokemons', 1, { expires: 365 });

        handleClick($(this).val()+'sort_pokemons');
    });

    body.off('click', '.parent__mainblock_load_more_button');
    body.on('click', '.parent__mainblock_load_more_button', function(){
        Cookies.set('page_pokemons', (+Cookies.get('page_pokemons') + 1), { expires: 365 });

        handleKenshi();
    });

    var search_int = '';
    body.off('change keyup', '#search-input');
    body.on('change keyup', '#search-input', function(){
        clearTimeout(search_int)
        search_int = setTimeout(function (){
            console.log('start_search')
            Cookies.set('page_pokemons', 1, { expires: 365 });
            Cookies.set('search_pokemons', $('#search-input').val(), { expires: 365 });

            handleClick($('#search-input').val()+'start_search');
            clearTimeout(search_int);
        }, 1200)
    });

    body.off('click', '.form-search img.form__img-close');
    body.on('click', '.form-search img.form__img-close', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });
        Cookies.set('search_pokemons', '', { expires: 365 });

        handleClick('end_search');
    });

    body.off('click', '.filter_power .filter__fieldset input');
    body.on('click', '.filter_power .filter__fieldset input', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });

        var i_attack = 1;
        var data_attack = '';
        $('.filter_power input').each(function (){
            if ($(this).prop('checked')){
                if (i_attack === 5){
                    data_attack += '5,6,7';
                }else{
                    data_attack += i_attack+',';
                }
            }
            i_attack++;
        });

        handleClick(data_attack+'edit_attac_search');
    });

    body.off('change', '.k_pokemon_f_alive, .k_pokemon_f_has_pokeboll');
    body.on('change', '.k_pokemon_f_alive, .k_pokemon_f_has_pokeboll', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });

        handleClick($(this).val()+$(this).prop('checked')+'edit_module_search');
    });

    body.off('click', '.filter__button-reset_show, .filter__button-reset');
    body.on('click', '.filter__button-reset_show, .filter__button-reset', function(){
        Cookies.set('page_pokemons', 1, { expires: 365 });

        handleClick('reset_module_search');
    });

    data.sort = Cookies.get('sort_pokemons');
    data.page = Cookies.get('page_pokemons');

    if (Cookies.get('check_filters') === '1'){
        var i_attack = 1;
        var menu_badge_val = 0;
        data.attack = '';
        $('.filter_power .filter__fieldset input').each(function (){
            if (i_attack === 6){
                i_attack = 1;
            }

            if ($(this).prop('checked')){
                if (i_attack === 5){
                    data.attack += '5,6,7,';
                }else{
                    data.attack += i_attack+',';
                }

                menu_badge_val++;
            }
            i_attack++;
        });

        if ( $($('.k_pokemon_f_alive')[0]).prop('checked') || $($('.k_pokemon_f_alive')[1]).prop('checked')){
            delete data.status;
        }else{
            data.status = 1;
        }

        if ( $($('.k_pokemon_f_has_pokeboll')[0]).prop('checked') || $($('.k_pokemon_f_has_pokeboll')[1]).prop('checked')){
            data.in_pokeball = 1;
        }else{
            delete data.in_pokeball;
        }

        if (data.attack !== ''){
            if (data.attack) {
                data.attack = data.attack.slice(0, -1);
            }
        }else{
            delete data.attack;
        }

        if (Cookies.get('search_pokemons') !== '' && Cookies.get('search_pokemons') !== undefined && Cookies.get('search_pokemons') !== 'undefined'){
            data.name = Cookies.get('search_pokemons');
        }else{
            delete data.name;
        }
    }else{
        Cookies.set('check_filters', '1', { expires: 365 });
        data.status = 1;
    }

    var pokemon_info_photo = '';
    var pokemonList = [];

    if (HistoryListOpen.length >= 1){
        pokemonList = HistoryListOpen;
    }

    var title_none= 'Никого не найдено'
    var title_sub= 'Попробуйте изменить параметры запроса'
    var image_empty = none_pokemon
    $('.empty').css('display', 'none');

    $('.empty__title').html(title_none);
    $('p.empty__subtitle').html(title_sub);
    $('img.empty__img').attr('src', image_empty);

    var next_page = 0;

    const counter = React.useRef(0);
    counter.current++;

    if (counter.current > 1) {
        $.ajax({
            url: site_config.new_api + '/pokemons?' + $.param(data),
            type: 'GET',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            async: false,
            success: function (data) {
                if (data.message === "Покемоны не найдены") {
                    title_none = 'Никого не найдено'
                    title_sub = 'Попробуйте изменить параметры запроса'
                    image_empty = none_pokemon
                    $('.empty').fadeIn();
                    $('.parent__mainblock_load_more').css('display', 'none');
                    return false;
                }

                next_page = data.next_page;
                data = data.data;

                var type = 'pokemons';
                if (data.length > 100) {
                    return false;
                }

                var data_lenght = data.length;

                if (data_lenght <= 0) {
                    title_none = 'Никого не найдено'
                    title_sub = 'Попробуйте изменить параметры запроса'
                    image_empty = none_pokemon
                    $('.empty').fadeIn();
                }

                for (var k_i = 0; k_i < data_lenght; k_i++) {
                    var iconBoll = false;
                    if (data[k_i].status === 1 && data[k_i].in_pokeball === 1) {
                        iconBoll = true;
                    }

                    var iconDeath = false;
                    if (data[k_i].status === 0) {
                        iconDeath = true;
                    }

                    var iconTitle = false;
                    if (data[k_i].legend) {
                        iconTitle = true;
                    }

                    if (data[k_i].photo_id) {
                        pokemon_info_photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/' + ('' + data[k_i].photo_id).padStart(3, '0') + '.png';
                    } else {
                        pokemon_info_photo = emptyList;
                    }

                    pokemonList.push({
                        img: pokemon_info_photo,
                        title: data[k_i].name,
                        iconBoll: iconBoll,
                        iconDeath: iconDeath,
                        iconTitle: iconTitle,
                        attack: Math.round(data[k_i].attack),
                        copy: data[k_i].id,
                        link: "/pokemon/" + data[k_i].id,
                    });
                }
                if (type === 'pokemons') {
                    if (next_page === false) {
                        setTimeout(function () {
                            $('.parent__mainblock_load_more').css('display', 'none');
                        }, 200);
                    } else {
                        setTimeout(function () {
                            $('.parent__mainblock_load_more').css('display', 'flex');
                        }, 200);
                    }
                }

            }, error: function () {
                title_none = 'Не удалось загрузить данные'
                title_sub = 'Попробуйте зайти через несколько минут или обновите страницу'
                image_empty = emptyList

                $('.empty__title').html(title_none);
                $('p.empty__subtitle').html(title_sub);
                $('img.empty__img').attr('src', image_empty);
                $('.empty').fadeIn();
            }
        });
    }

    return (
        <>
            <section className="cards">
                <div className="container">
                    <div>
                        <ul className="cards__list">
                            {pokemonList.map((item, index) => {
                                return (
                                    <div data-href={item.link} className="cards__link pokemons__link">
                                        <li
                                            className="cards__item"
                                            data-death={item.iconDeath}
                                            data-legend={item.iconTitle}
                                        >
                                            <div className="cards__img">
                                                <img
                                                    src={item.img !== "" ? item.img : pokemonEmpty}
                                                    alt="icon"
                                                    className="cards__image"
                                                    data-death={item.iconDeath}
                                                />
                                            </div>
                                            <div className="cards__content">
                                                <h4 className="cards__title">{item.title}</h4>
                                                <div
                                                    className="cards__title-icon"
                                                    data-coin={item.iconTitle}
                                                ></div>
                                            </div>
                                            <div className="cards__content">
                                                <div
                                                    className="cards__icon"
                                                    data-boll={item.iconBoll}
                                                    data-death={item.iconDeath}
                                                ></div>
                                                <Level level={item.attack} name="Атака"/>
                                                <Copy copyCount={item.copy} hidden={true}/>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <div className="parent__mainblock_load_more" style={{'display': 'none'}}>
                            <button className="parent__mainblock_load_more_button">Загрузить еще</button>
                        </div>
                    </div>
                    <EmptyList
                        image={image_empty}
                        title={title_none}
                        subtitle={title_sub}
                    />
                </div>
            </section>
        </>
    );
}

export default CardList;
