import React, {useState} from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../../../images/logo-studio.svg";

import "../Auth.css";
import "../Restore/Restore.css";

import Cookies from 'js-cookie';
import site_config from "../../kenshi_config/kenshi_config"
import {MainFunc} from "../../kenshi_config/kenshi_functions"
import $ from "jquery";
import Footer from "../../Footer/Footer";

const NewPasswordMail = () => {
  const [toggle, setToggle] = useState(true);
  const handleChange = (event) => {
    const input = event.target;
    const icon = input.closest('.auth__wrap').querySelector('.auth__input-icon');
    icon.style.display='block';
    if(input.value.length == 0) {
      icon.style.display='none';
    }
  }

  const handleClick = (event) => {
    const icon = event.target;
    const input = icon.closest('.auth__wrap').querySelector('.auth__input-pass');
    if(toggle) {
      input.type = 'text';
      icon.classList.add('view-pass');
    }  else {
      input.type = 'password';
      icon.classList.remove('view-pass');
    }
    setToggle(!toggle);
  }

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
  });
  const onSubmit = (data) => {
    let k_error = 0;

    var email_check = MainFunc.k_check_field('.k_form_re_mail', 'email');
    if (!email_check) {
        k_error = 1;
    }

    if ( k_error === 0){
        $.ajax({
            url: site_config.new_api + '/trainers/re',
            type: 'POST',
            dataType: 'json',
            data: {action: 're_send', email: $('.k_form_re_mail').val()},
            success: function(data){
                window.location.href = '/confirmation';
                reset();
            }, error: function (){
                $('.k_main_error_mail').text('Аккаунт с такой почтой не найден');
                $('.k_main_error_mail').fadeIn();
            }
        });
        
    }

    
  };
  return (
    <>
      <main className="content">
        <div className="gradient auth_gradient"></div>
        <section className="auth restore auth-full">
          <div className="auth__content register__content">
            <h1 className="auth__title">Битва покемонов</h1>
            <div className="auth__mobile auth__mobile-top">
              <a href="/login" className="auth__back"></a>
              <p className="auth__title-h2">Восстановление пароля</p>
            </div>
            <p className="auth__text auth__text_small">
              Введите почту, которую указывали при регистрации{" "}
            </p>
            <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
              <div className="auth__wrap">
                  <input
                      type="email"
                      className="auth__input k_form_re_mail"
                      {...register("email", {
                          required: "Введите почту",
                          pattern: {
                              value:
                                  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                              message: "Введите почту",
                          },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                      placeholder=' '
                  />
                  <label className="auth__label">Почта</label>
                  <span className="auth__error k_main_error_mail">{errors.email?.message}</span>
              </div>
              <button type="submit" className="auth__button k_margin_top_15">
                  Восстановить
              </button>
            </form>
            <Link to="/login" className="auth__text auth__registr reg_form_button" style={{display:'block'}}>
                Войти
            </Link>
          </div>
        </section>
      </main>
        <div className="footer_no_line">
            <Footer />
        </div>
    </>
  );
};

export default NewPasswordMail;
