const KenshiConfig = [];

KenshiConfig['main_url'] = process.env.REACT_APP_MAIN_URL;
KenshiConfig['main_port'] = process.env.REACT_APP_MAIN_PORT;
KenshiConfig['main_port_url'] = process.env.REACT_APP_MAIN_PORT_URL;
KenshiConfig['main_telegram_name'] = process.env.REACT_APP_MAIN_TELEGRAM_NAME;
KenshiConfig['link_bot'] = process.env.REACT_APP_LINK_BOT;

KenshiConfig['new_api'] = process.env.REACT_APP_NEW_API;
KenshiConfig['lavka_api'] = process.env.REACT_APP_LAVKA_API;
KenshiConfig['tech_api'] = process.env.REACT_APP_TECH_API;
KenshiConfig['rating_api'] = process.env.REACT_APP_RATING_API;

KenshiConfig['main_url_react'] = process.env.REACT_APP_MAIN_URL_REACT;

KenshiConfig['tech_api_vk_secret_public_hash'] = process.env.REACT_APP_TECH_API_VK_SECRET_PUBLIC_HASH;
KenshiConfig['tech_api_vk_app_id'] = process.env.REACT_APP_TECH_API_VK_APP_ID;

KenshiConfig['app_kotik_info'] = process.env.REACT_APP_KOTIK_INFO;

export default KenshiConfig;