import "./Status.css";

function Status({ active, full }) {
  return (
    <>
      <ul className="status-n">
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
        <li className="status-n__item" data-active={active} data-full={full}></li>
      </ul>
    </>
  );
}

export default Status;
