import React from "react";
import logo from "../../../images/logo-studio.svg";
import { Link } from "react-router-dom";
import "../Auth.css";
import Footer from "../../Footer/Footer";

function Сonfirmation({ title, subtitle, text, text2 }) {
  return (
    <main className="content">
      <div className="gradient auth_gradient"></div>
      <section className="auth restore auth-full">
        <div className="auth__content register__content">
          <h1 className="auth__title">{title}</h1>
          <div className="auth__mobile auth__mobile-top k_margin_bottom_16">
            <a href="/login" className="auth__back"></a>
            <p className="auth__title-h2">{subtitle}</p>
          </div>
          <div className="auth__confirmation-wrapper" style={{'width': '360px'}}>
            <p className="auth__confirmation">{text}</p>
            <p className="auth__confirmation">{text2}</p>
          </div>
          <Link to="/login" className="auth__confirmation-btn">
            Войти
          </Link>
        </div>
      </section>
      <div className="footer_no_line">
        <Footer />
      </div>
    </main>
  );
}

export default Сonfirmation;
