import download from "../../../../images/download.svg";
import "./ExportHistory.css";

function ExportHistory({ status }) {
  return (
    <>
      <div className="export k_cart_trainer_export" style={{visibility:'hidden'}}>
        <img src={download} className="export__icon" />
        <div className="export__box">
          <div className="export__title">Экспорт истории битв</div>
          <div className="export__descr">{status}</div>
        </div>
        <button className="export__button k_button_get_history">
          <span className="export__button_border"></span>
          <span>Заказать</span>
        </button>
      </div>
    </>
  );
}

export default ExportHistory;
