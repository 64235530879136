import React  from "react";

import logo from "../../images/Logo.png";
import number from "../../images/studio-number.svg";
import logo_healtcheck from "../../images/healtcheck.png";
import file from "../../images/file-text.svg";
import rules from "../../images/rules.svg";
import "./Footer.css";
import NotFound from "../NotFound/NotFound";
import {Route} from "react-router-dom";

function Footer() {
  return (
    <footer className="footer container">
        <div className='footer__container'>
            <div className="footer__logo footer_fix">
                <a className='status_url' href="https://status.pokemonbattle.ru" target="_blank"><img src={logo_healtcheck}/></a>
                <img className="footer__number footer_num_fix" src={number} alt="" />
                <a href="https://qa.studio/" target="_blank"><img className="footer__studio" src={logo} alt="логотип"/></a>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
