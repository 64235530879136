import React, {useEffect} from "react";
import "./PopupSort.css";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

Cookies.set('k_fix_filter_set', '-1', { expires: 365 });
var initiate_sort = false;
function PopupSort(props) {
  const popupClassName = props.isOpen ? "popup-sort_opened" : "popup-sort";
  const popupMobileClassName = props.isOpen
    ? "popup-sort-mobile_opened"
    : "popup-sort-mobile";

  function closeMobilePopup() {
    props.onclose();
  }

  //Меняем дефолтное значение на выбранное в списке
  const onOptionClicked = (value) => () => {
    props.setSelectedOption(value);
    for (var i=0; i<props.options.length; i++){
      if (props.options[i][0] === value){
        var value_set = props.options[i][1];
        break;
      }
    }

    Cookies.set('k_fix_filter_set', value_set, { expires: 365 });
    props.onclose();
  };

  useEffect(() => {
    var props_input = props.options[0][1];

    var k_fix_filter_set = Cookies.get('k_fix_filter_set');
    if (k_fix_filter_set !== '-1'){
      props_input = k_fix_filter_set;
    }
    setTimeout(function(){
      $('.k_coach_sort_pc input').each(function(){
        if($(this).val() == props_input){
          $(this).prop('checked', true);

          $('.pokemon__sort p.pokemon__text').html($(this).parent().text())
        }
      });
      $('.k_coach_sort_mobile input').each(function(){
        if($(this).val() == props_input){
          $(this).prop('checked', true);
          $(this).change();

          $('.pokemon__sort p.pokemon__text').html($(this).parent().text())
        }
      });
    }, 500);
  }, []);

  //Меняем дефолтное значение на выбранное в списке - Мобильное
  const onOptionClickedMobile = (value) => () => {
    props.setSelectedOption(value);
    for (var i=0; i<props.options.length; i++){
      if (props.options[i][0] === value){
        var value_set = props.options[i][1];
        break;
      }
    }

    Cookies.set('k_fix_filter_set', value_set, { expires: 365 });
  };

  if (initiate_sort === false){
    initiate_sort = true;
  }

  return (
    <div>
      <div className={popupClassName}>
        <h3 className="popup__title">Сортировка</h3>
        <form className="popup__form k_coach_sort_pc">
          {props.options.map((option, index) => {
            return (
              <label className="label" key={index}>
                {option[0]}
                <input
                  type="radio"
                  name="radio"
                  className="radio"
                  value={option[1]}
                  onChange={onOptionClicked(option[0])}
                />
                <span className="custom-radio"></span>
              </label>
            );
          })}
        </form>
      </div>

      {/* Мобильная версия */}
      <div className={popupMobileClassName}>
        <div className="popup-sort-mobile__container">
          <button
            type="button"
            className="popup-mobile__btn-close"
            onClick={closeMobilePopup}
          ></button>
          <h3 className="popup-mobile__title">Сортировка</h3>
          <form className="popup-mobile__form k_coach_sort_mobile">
            {props.options.map((option, index) => {
              return (
                <label className="label-mobile" key={index}>
                  {option[0]}
                  <input
                    type="radio"
                    name="radio"
                    className="radio-mobile"
                    value={option[1]}
                    onChange={onOptionClickedMobile(option[0])}
                  />
                  <span className="custom-radio-mobile"></span>
                </label>
              );
            })}
            <button
              type="button"
              className="popup-mobile__btn"
              onClick={closeMobilePopup}
            >
              Применить
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PopupSort;
