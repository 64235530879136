import "./Level.css";

function Level({ level, name }) {
  return (
    <>
      <div className="level" data-level={level}>
        <p className="level__name" data-level={level}>
          {name}
        </p>
        <span className="level__count" data-level={level}>
          {level}
        </span>
      </div>
    </>
  );
}

export default Level;
