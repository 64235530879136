import React from "react";
import Level from "../Level/Level";

import defeatIcon from "../../images/defeat.svg";

import "./HistoryBattls.css";

const HistoryBattlsItem = ({
  id,
  enimyId=''+enimyId,
  attack,
  enimyAttack,
  icon,
  enimyIcon,
  pokemonName,
  enimyPokemonName,
  defeat = false,
}) => {
  return (
    <>
      <div className="history-item k_trassing_block_battles_none k_trassing_block_battles" style={{display:'none'}}>
        <time className="date">31.12.22</time>
        <div className="history-item__content">
          <div
            className={defeat ? "history-card k_trassing_r_my_pokemon" : "history-card k_trassing_r_my_pokemon"}
          >
            <div className="history-card__box pokemons__link_h" data-href-pokemon={id}>
              <img className="history-card__image" src={icon} alt="" />
              <div className="history-card__id pok_id">{id}</div>
            </div>
            <div className="history-card__box">
              <div className="history-card__title">{pokemonName}</div>
              <Level level={attack} name="Атака" />
              <div className="history-mob pok_id">{id}</div>
            </div>

            <div className="icon-batlle"></div>
          </div>

          <div
            className={defeat ? "history-card k_trassing_r_other_pokemon" : "history-card k_trassing_r_other_pokemon"}
          >
            <div className="icon-batlle"></div>
            <div className="history-card__box pokemons__link_h" data-href-pokemon={enimyId}>
              <img className="history-card__image" src={enimyIcon} alt="" />
              <div className="history-card__id pok_id">{enimyId}</div>
            </div>
            <div className="history-card__box">
              <div className="history-card__title">{enimyPokemonName}</div>
              <Level level={enimyAttack} name="Атака" />
              <div className="history-mob pok_id">{enimyId}</div>
            </div>
          </div>
        </div>
        <div
          className={
            defeat
              ? "history-item__bottom defeat"
              : "history-item__bottom victory"
          }
        >
          {defeat && <img src={defeatIcon} width="16" height="18" />}
          <span>{defeat ? "Поражение" : "Победа"} Пикачу!</span>
        </div>
      </div>
    </>
  );
};

export default HistoryBattlsItem;
