import React, { useState } from "react";
import arrowLeft from "../../images/arrow-left.svg";

import "../Auth/Auth.css";
import "../Auth/Login/Login.css";
import "./Rating.css";
import Setting from "../Setting/Setting";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import $ from "jquery";
import site_config from "../kenshi_config/kenshi_config";
import Cookies from "js-cookie";
import kenshi_config from "../kenshi_config/kenshi_config";
import { useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {MainFunc} from "../kenshi_config/kenshi_functions";
import icon_align_end_horizontal from "../../images/icons/rating/icon_align_end_horizontal.svg";
import icon_download from "../../images/icons/rating/icon_download.svg";
import icon_percent from "../../images/icons/rating/icon_percent.svg";
import icon_russian_ruble from "../../images/icons/rating/icon_russian_ruble.svg";
import icon_star from "../../images/icons/rating/icon_star.svg";
import icon_swords from "../../images/icons/rating/icon_swords.svg";
import icon_user from "../../images/icons/rating/icon_user.svg";

function k_get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

export default function Rating({ status }) {
    $(document).scroll(function() {
        if ($('.rating_table')[0]) {
            if ($('.rating_table')[0].offsetTop < ($(document).scrollTop())) {
                $('table.rating_table_content').addClass('sticky');
            } else {
                $('table.rating_table_content').removeClass('sticky');
            }
        }
    })

    let navigate = useNavigate();
    const {
        register,
        getValues,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onSubmit",
    });

    Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

    useState(() => {
        setTimeout(function () {
            $.ajax({
                url: site_config.rating_api + '/rating',
                type: 'GET',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                cache: true,
                async: false,
                success: function (data) {
                    console.log(data)
                    var table_rate = data.table;
                    var date_cache = data.last_update;

                    var date = new Date(date_cache);
                    $('.k_rating_span_date .k_date').text(date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear().toString() + ' ' + addZero(date.getHours().toString()) + ':' + addZero(date.getMinutes().toString()));


                    $('.rating_table_content tbody .k_rating_table_r').html('');
                    for (var i=0; i < table_rate.length; i++){
                        var row = $('.k_table_example').clone();

                        row.removeClass('k_table_example');
                        row.addClass('k_rating_table_r');

                        var rank = table_rate[i].rank;
                        if (rank !== -1 && rank > 0 && rank <= 101){
                            if (rank === 1){
                                row.find('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_1');
                            } else if (rank === 2){
                                row.find('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_2');
                            } else if (rank === 3){
                                row.find('.k_cart_trainer_rate_img').addClass('k_cart_trainer_rate_img_3');
                            } else if (rank > 3){
                                row.find('.k_cart_trainer_rate_img').html(rank);
                            }
                        }

                        row.find('.rating_table_r_trainer_name').html(table_rate[i].name);
                        row.find('.rating_table_r_rate').html(table_rate[i].rating);
                        row.find('.rating_table_r_battles').html(table_rate[i].battles_num);
                        row.find('.rating_table_r_wins').html(Number(((table_rate[i].battles_wins / table_rate[i].battles_num) * 100).toFixed(1)));
                        row.find('.rating_table_r_v_p').html(table_rate[i].battles_wins + "/" + table_rate[i].battles_defeat);
                        row.find('.rating_table_r_sells').html(table_rate[i].total_amount);
                        row.find('.rating_table_r_downloads').html(table_rate[i].downloads_num);
                        row.attr('data-href', '/trainer/'+table_rate[i].trainer_id)

                        $('.rating_table_content tbody').append(row);
                    }

                }
            });
            var int_rating = setInterval(function (){
                if (!$('.rating_table_content tbody')[0]){
                    clearInterval(int_rating);
                }else{
                    if ($('.rating_table_content tbody')[0].scrollTop > 0){
                        $('.rating_table_content').addClass('scroll');
                    }else{
                        $('.rating_table_content').removeClass('scroll');
                    }
                }
            }, 100);
        }, 100);
    });

    const [toggle, setToggle] = useState(true);

    return (
        <>
            <Header />
            <main className="content">
                <div className="gradient"></div>
                <div className="pokemon__content container k_rating_span_right">
                    <div>
                        <h1 className="pokemon__title pokemon__title_rating">Рейтинг</h1>
                        <span className="pokemon__sub_title">
                            100 лучших тренеров «Битвы Покемонов»
                        </span>
                    </div>
                    <font>Сезон 2024</font>
                </div>
                <section className="rating_body container">
                    <div className="rating_table">
                        <table className="rating_table_content">
                            <thead>
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col"><img src={icon_user} alt="icon"/> <font>Тренер</font></th>
                                    <th scope="col"><img src={icon_star} alt="icon"/> <font>Рейтинг</font></th>
                                    <th className="hidden_mobile" scope="col"><img src={icon_swords} alt="icon"/> <font>Всего битв</font></th>
                                    <th className="hidden_mobile" scope="col"><img src={icon_percent} alt="icon"/> <font>Победы</font></th>
                                    <th className="hidden_mobile" scope="col"><img src={icon_align_end_horizontal} alt="icon"/> <font>В/П</font></th>
                                    <th className="hidden_mobile" scope="col"><img src={icon_russian_ruble} alt="icon"/> <font>Потрачено</font></th>
                                    <th className="hidden_mobile" scope="col"><img src={icon_download} alt="icon"/> <font>Загрузок</font></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="k_table_example trainers__link">
                                    <td><div className="k_cart_trainer_rate_img"></div></td>
                                    <td className="rating_table_r_trainer_name trainers__title-text"></td>
                                    <td className="rating_table_r_rate"></td>
                                    <td className="rating_table_r_battles hidden_mobile"></td>
                                    <td className="rating_table_r_wins hidden_mobile"></td>
                                    <td className="rating_table_r_v_p hidden_mobile"></td>
                                    <td className="rating_table_r_sells hidden_mobile"></td>
                                    <td className="rating_table_r_downloads hidden_mobile"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="pokemon__content container k_rating_span_right k_rating_span_date">
                    <font>Данные на <font className="k_date">06.06.2024 00:05</font></font>
                </div>
            </main>
            <Footer />
        </>
    );
}
