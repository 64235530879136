import React from "react";
import ComponentMobile from "./ComponentMobile";
import ball from "../../images/header-pokemon.svg";
import arrow from "../../images/blue-vextor.svg";
import { useNavigate } from "react-router-dom";
import error from "../../images/error.svg";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";

function dayTitle(number) {
  if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
  var last_num = number%10;
  if (last_num == 1) return 'день';
  if ([2,3,4].includes(last_num)) return 'дня';
  if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

var misstake_load = false;
var user_buy_id = '';
var user_buy_price = '';

const BuyMistake = (props) => {
  const [infoState, setState] = React.useState(false)
  const navigate = useNavigate()
  const [screenWidth, setWidth] = React.useState(window.screen.width)
  const findScreenWidth = () => {
    setWidth(window.screen.width)
  }

  if (misstake_load === false) {
    if(Cookies.get('user_buy_product') == ''){
      window.location.href = '/shop';
    }else{
      if (Cookies.get('order_type') === 'premium') {

        user_buy_id = 0;
        user_buy_price = Cookies.get('premium_price');
        setTimeout(function (){
          //$('.submit__button-for-success, .payment__back-button-success .payment-adv-blue').html('Вернуться в битву покемонов');
          $('.k_info_pr').html('Pokemon Premium: ' + Cookies.get('premium_day') + ' ' + dayTitle(Cookies.get('premium_day')));
          $('body').on('click', '.payment__back-button-success, .submit__button-for-success', function (){
            setTimeout(function (){
              window.location.href = '/premium';
            }, 500);

            return false;
          })
        }, 500);
      }else{
        user_buy_id = Cookies.get('user_buy_product');
        user_buy_price = Cookies.get('user_buy_price');

        setTimeout(function (){
          $('body').on('click', '.payment__back-button-success, .submit__button-for-success', function (){
            setTimeout(function (){
              window.location.href = '/shop';
            }, 500);

            return false;
          })
        }, 500);
      }

        Cookies.set('user_buy_product', '', { expires: 365 });
        Cookies.set('user_buy_card', '', { expires: 365 });
        Cookies.set('user_buy_card_full', '', { expires: 365 });
        Cookies.set('user_buy_price', '', { expires: 365 });
        Cookies.set('user_buy_code', '', { expires: 365 });
        Cookies.set('user_buy_name', '', { expires: 365 });
        Cookies.set('user_buy_date', '', { expires: 365 });
    }
    misstake_load = true;
  }

  React.useEffect(() => {
    window.addEventListener('resize', findScreenWidth)
    return () => {
      window.removeEventListener('resize', findScreenWidth)
    }
  }, [])
  return (
    <>
      {infoState && <ComponentMobile onClick={() => setState(!infoState)}
      />
      }
      <header className="payment__header">
        <div className="payment__logo">
          <img src={ball} alt='logo' className="payment__logo-img" />
          <div className="payment__text">
            <p className="payment__bigtext">Пикачунькофф</p>

          </div>

        </div>
      </header>
      <main className='payment__main'>

        {screenWidth <= 450 && <div className="payment__paytitle"><button onClick={() => navigate('/shop')} className="payment__paybutton-top" /><p className="payment__title-top">Оплата</p></div>}
        <div className={infoState ? "payment__sublock-wrapper-v2 payment__height" : 'payment__sublock-wrapper-v2'}>
          <div className="pay__select-block">
            <p className="payment__info-text-new">{user_buy_price} ₽</p>
            <button
              onClick={() => setState(!infoState)}
              className={infoState ?
                "payment__info-button-v2  payment__info-button-v2-rotate" :
                "payment__info-button-v2"}
            ></button>

          </div>
          <div
            className={
              infoState
                ? "payment__subblock "
                : "payment__subblock payment__subblock-invisible-state-v2"
            }
          >
            <p className="payment__subtitle-top">Магазин</p>
            <span className="payment__shopname"><a href={'https://'+site_config.main_url_react}>{site_config.main_url_react}</a></span>
            <p className="payment__subtitle-top">Номер заказа</p>
            <span className="payment__span">pb:3112220001</span>
            <p className="payment__subtitle-top">Описание</p>
            <span className="payment__span k_info_pr">Аватар Тренера № {user_buy_id}</span>
          </div>
        </div>
        <div className="payment__form-container-defolt">
          <form className="payment__padding-for-mistake payment__form-defolt-for-buymistake-v2">
            <div className="payment__field-defolt-for-buymistake-v2">
              <div className="payment__success1">
                <img className="success__image" alt='success' src={error} />
                <h3 className="payment__font-for-success payment__fielheader-for-success">Ошибка оплаты.</h3>
              </div>
              {screenWidth >= 450 && (
                <p className="textmaun">
                  Недостаточно средств для оплаты. Пополните счёт или оплатите другим способом
                </p>
              )}
              {screenWidth >= 451 && <button onClick={function (){return false;}} className="payment__back-button-success">
                <img alt='strelka' src={arrow} className="payment__back-svg-blue" />
                <p className="payment__adv payment-adv-blue " >Вернуться в магазин</p>
              </button>}
            </div>
          </form>
        </div>   
        {screenWidth <= 450 && <button onClick={function (){return false;}} className="white-color payment__submit-button submit__button-for-success" type='submit'>Вернуться в магазин</button>}
      </main>

    </>
  )
}

export default BuyMistake;