import React from "react";
import "./PokemonSingle.css";
import pokemonBig from "../../images/pokemonBig.png";
import PokemonData from "./PokemonData/PokemonData";
import History from "./History/History";
import cherep from "../../images/pokemon-del.svg";
import ball from "../../images/header-pokemon.svg";
import deadLegend from "../../images/deadgolden.svg";
import liveLegend from "../../images/goldenLive.svg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BackButton from "../BackButton/BackButton";

function PokemonSingleLoose(props) {
  const [isOpen, open] = React.useState(false);
  const [screenWidth, setWidth] = React.useState();
  const findScreenWidth = () => {
    setWidth(window.screen.width);
  };

  React.useEffect(() => {
    window.addEventListener("resize", findScreenWidth);
    return () => {
      window.removeEventListener("resize", findScreenWidth);
    };
  }, []);

  const nameBySize =
    screenWidth <= 500 && props.isGolden
      ? "Покемон-легенда"
      : "Легендарный покемон";

  const pokemonStyle = props.isDead
    ? "pokemon-card__image_type-dead pokemon-card__image"
    : "pokemon-card__image";

  return (
    <>
      <Header />
      <section className="pokemon-card">
        <div className="pokemon-card__centr">
          {!(screenWidth <= 767 && isOpen) && <BackButton />}
          <div
            className={
              screenWidth <= 767 && isOpen
                ? "pokemon-card__container_type-invisible"
                : "pokemon-card__container"
            }
          >
            {!(screenWidth <= 767 && isOpen) && (
              <img className={pokemonStyle} src={pokemonBig} alt="pokemon" />
            )}
          </div>
          <div
            className={
              isOpen
                ? "pokemon-card__stats-container_type_open"
                : "pokemon-card__stats-container"
            }
          >
            <p
              className={
                screenWidth <= 767 && isOpen
                  ? "pokemon-card__text_type-invisible"
                  : "pokemon-card__text"
              }
            >
              {props.isGolden ? nameBySize : "Покемон"}
            </p>
            <div className="pokemon-card__status-block">
              <h2
                className={
                  screenWidth <= 767 && isOpen
                    ? "pokemon-card__heading_type-invisible"
                    : "pokemon-card__heading"
                }
              >
                Жестеезавр
              </h2>
              {props.withBall && (
                <img
                  className={
                    screenWidth <= 767 && isOpen
                      ? "pokemon-card__status-image_type-invisible"
                      : "pokemon-card__status-image"
                  }
                  src={ball}
                  alt="status"
                />
              )}
              {props.isGolden && !props.isDead && (
                <img
                  className={
                    screenWidth <= 767 && isOpen
                      ? "pokemon-card__status-image_type-invisible"
                      : "pokemon-card__status-image-legend"
                  }
                  src={liveLegend}
                  alt="status"
                />
              )}
              {props.isGolden && props.isDead && (
                <img
                  className={
                    screenWidth <= 767 && isOpen
                      ? "pokemon-card__status-image_type-invisible"
                      : "pokemon-card__status-image-legend-dead"
                  }
                  src={deadLegend}
                  alt="status"
                />
              )}
              {props.isDead && !props.isGolden && (
                <img
                  className={
                    screenWidth <= 767 && isOpen
                      ? "pokemon-card__status-image_type-invisible"
                      : "pokemon-card__status-image-dead"
                  }
                  src={cherep}
                  alt="status"
                />
              )}
            </div>

            {isOpen && (
              <div className="pokemon-card__button-top top">
                <p className="pokemon-card-title">История битв2</p>
                <span className="pokemon-card__history pokemon-card__history_type-top" />
                <span className="pokemon-card__counter pokemon-card__counter_type-top">
                  3
                </span>
                <button
                  onClick={() => open(!isOpen)}
                  type="button"
                  className="pokemon-card__right-top"
                />
              </div>
            )}

            {!isOpen && (
              <PokemonData
                isWinner={props.isDead}
                level={4}
                isChampion={props.isDead}
              />
            )}
            {isOpen && <History isWinner={!props.isDead} />}
            {!isOpen && (
              <div className={"pokemon-card__button"}>
                <p className="pokemon-card-title">История битв</p>
                <span className="pokemon-card__history" />
                <span className="pokemon-card__counter">3</span>
                <button
                  onClick={() => {
                    setWidth(window.screen.width);
                    open(!isOpen);
                  }}
                  type="button"
                  className="pokemon-card__right"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PokemonSingleLoose;
