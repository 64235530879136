import React from "react";
import "../../Filter/FilterPokemon/FilterPokemon.css";
import Checkbox from "../Checkbox";

function TrainerPokemon(props) {
  const popupClassName = props.isOpenFilter
    ? "popup-filter_opened"
    : "popup-filter";
  const popupMobileClassName = props.isOpenFilter
    ? "popup-filter-mobile_opened"
    : "popup-filter-mobile";

  //Закрытие мобильного попапа
  function closeMobilePopup() {
    props.onclose();
    //props.setStateBtnFilter(true);
  }

  return (
    <div>
      <div className={popupClassName}>
        <div className="popup__filter-container">
          <h3 className="popup__title">Фильтры</h3>
          <button
            type="button"
            className={
              props.hiddenReset
                ? "filter__button-reset_show filter__button-reset"
                : "filter__button-reset"
            }
            onClick={props.counterSelectedDelete}
            hidden={props.hiddenReset}
          >
            Сбросить
          </button>
        </div>
        <div className="popup__filter trainer-filter filter_power">
          <Checkbox counterSelected={props.counterSelected} name="Уровень" hidden={true} />
        </div>
      </div>

      <div className={popupMobileClassName}>
        <div className="popup-filter-mobile__container">
          <button
            type="button"
            className="popup-mobile__btn-close"
            onClick={closeMobilePopup}
          ></button>
          <h3 className="popup__title">Фильтры</h3>
          <div className="popup__filter filter_power">
            <Checkbox counterSelected={props.counterSelected} name="Уровень" hidden={props.hidden} />
          </div>
          <button type="button" className="popup-mobile__btn">
            Применить
          </button>
        </div>
      </div>
    </div>
  );
}

export default TrainerPokemon;
