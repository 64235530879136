// import './';
import Header from "../Header/Header";
import TrainerFilter from "./TrainerFilter/TrainerFilter";
import TrainerList from "./TrainerList/TrainerList";
import Footer from "../Footer/Footer";

function Coaches() {
  return (
    <>
      <Header />
      <main className="content">
        <div className="gradient"></div>
        <TrainerFilter />
        <TrainerList />
      </main>
      <Footer />
    </>
  );
}

export default Coaches;
