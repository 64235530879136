import React from "react";
import { Routes, Route } from "react-router-dom";
/*  Подключение компонентов   */
import Main from "../Main/Main";
import Coaches from "../Coaches/Coaches";
import Shops from "../Shops/Shops";
import Premium from "../Premium/Premium";
import NotFound from "../NotFound/NotFound";
import PokemonSingle from "../PokemonSingle/PokemonSingle";
import TrainerItem from "../Coaches/TrainerItem/TrainerItem";
import Login from "../Auth/Login/Login";
import Tracer from "../Tracer/Tracer";
import Registration from "../Auth/Registration/Registration";
import RestorePassword from "../Auth/RestorePassword/RestorePassword";
import NewPassword from "../Auth/NewPassword/NewPassword";
import NewPasswordMail from "../Auth/NewPasswordMail/NewPasswordMail";
import PokemonSingleLoose from "../PokemonSingle/PokemonSingleLoose";
import ComponentDefolt from "../PaymentForm/ComponenDefolt";
import Confirmation from "../Auth/Сonfirmation/Сonfirmation";
import ConfirmationOk from "../Auth/Сonfirmation/ConfirmationOk";
import RegistrationVK from "../Auth/Registration/RegistrationVK";
import SuccessPayment from "../PaymentForm/SuccessPayment";
import BuyMistake from "../PaymentForm/BuyMistake";
import PaymentFormV2 from "../PaymentForm/PaymentFormV2";
import Loguru from "../Loguru/Loguru";
import Rating from "../Rating/Rating";
import PokemonsGalereya from "../PokemonsGalereya/PokemonsGalereya";
import Profile from "../Profile/Profile";

/*  Подключение стилей   */
import "../../vendor/fonts/fonts.css";
import "./App.css";
/*  Обновление   */

import { useNavigate } from "react-router-dom";

import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";
import KenshiConfig from "../kenshi_config/kenshi_config";
import {MainFunc} from "../kenshi_config/kenshi_functions";
import TrainersGalereya from "../TrainersGalereya/TrainersGalereya";

var k_show_error_screen = false;

var start_time;
$.ajaxSetup({
    beforeSend: function () {
        if (this.url !== site_config.new_api + '/technical_routes/save_trace') {
            start_time = new Date().getTime();
        }
    }
});

function dayTitle(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number%100)) return 'дней';
    var last_num = number%10;
    if (last_num == 1) return 'день';
    if ([2,3,4].includes(last_num)) return 'дня';
    if ([5,6,7,8,9, 0].includes(last_num)) return 'дней';
}

$( document ).ajaxSuccess(function( event, xhr, options, data ){
    var device_id = Cookies.get('device_id', false);

    if (device_id){
        var request_time = new Date().getTime() - start_time;

        $.ajax({
            url: site_config.new_api + '/technical_routes/save_trace',
            type: 'POST',
            contentType: "application/json",
            global: false,
            data: JSON.stringify({
                'device_id': device_id,
                'status': ''+xhr.status,
                'ms': ''+request_time,
                'body': JSON.stringify(data),
                'title': options.url
            }),
            dataType: 'json'
        });
    }
})


function k_get(name){
    if(name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(window.location.search))
        return decodeURIComponent(name[1]);
}

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    $(window).on('scroll', function () {
        var header = $('.k_sticky_header');
        if (header[0]) {
            if (window.pageYOffset > (header[0].offsetTop + header.height())) {
                header.addClass("pokemon_sticky");
                $('.cards, .trainers').css({'margin-top': '147px'})
            } else {
                header.removeClass("pokemon_sticky");
                $('.cards, .trainers').css({'margin-top': 0})
            }
        }
    })
}

var thisses_arr = [];
$('body').on('click', '.copy', function (event){
    event.stopPropagation();
    thisses_arr.push(this);
    var thisses = this;
    if ($(this).find('.copy__text').attr('data-block') != 'true') {
        navigator.clipboard.writeText($(thisses).find('.copy__text').text()).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__btn').css('opacity', '0.3');
            $(thises_param).find('.copy__text').attr('data-block', 'true');
        }, 50, thisses);

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__btn').css({'opacity': '0.5', 'scale': '0.5'});
        }, 100);

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__btn').css({'opacity': '1', 'scale': '1'});
        }, 200, thisses);

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__text').fadeOut(100);
        }, 300, thisses);

        setTimeout(function ( thises_param ) {
            if ($(thises_param).attr('data-last') == '' || !$(thises_param).attr('data-last')) {
                $(thises_param).find('.copy__text').attr('data-last', $(thises_param).find('.copy__text').text());
                $(thises_param).find('.copy__text').text('Скопировано');
            }

            $(thises_param).find('.copy__text').fadeIn(300);
        }, 400, thisses);

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__text').fadeOut(100);
        }, 1000, thisses);

        setTimeout(function ( thises_param ) {
            $(thises_param).find('.copy__text').fadeIn(300);
            $(thises_param).find('.copy__text').text($(thises_param).find('.copy__text').attr('data-last'));
            $(thises_param).find('.copy__text').attr('data-last', '');
            $(thises_param).find('.copy__text').attr('data-block', 'false');
        }, 1100, thisses);
    }
    return false;
});

/* НАЧАЛО контроль положения градиента и система списков на страницах покемона и тренера*/
$(window).on('resize load', function(){
    var trainer_img = $('.trainer-img');
    if (trainer_img[0]){
        $('.single-gradient').css('left', ((trainer_img.offset().left-$('.single-gradient').width()/2)+(trainer_img.width()/2) - 50)+'px');
    }
});

$(window).on('resize', function(){
    var trainer_img = $('.trainer-img');
    if (trainer_img[0]){
        var content_h = MainFunc.trainer_or_pokemons_open_lists();

        if (content_h !== 0){
            $('.pokemons-dropdown__list, .history__list').css('height', content_h+'px');
        }
    }
});
/* КОНЕЦ (градиент и списки на страницах покемонов и тренеров)*/


function App() {
    const navigate = useNavigate();

    console.log(k_get('confirm_mail'));

    if (k_get('confirm_mail')){
        $.ajax({
            url: site_config.new_api + '/trainers/confirm_email?key='+k_get('confirm_mail'),
            type: 'GET',
            dataType: 'json',
            async: false,
            success: function(data){
                window.location.href = '/confirmation-ok-2';
            }, error: function (jqXHR){
                k_show_error_screen = jqXHR.status;
            }
        });
    }

    $('body').off('click', '.trainers__link, .trainer_link_info')
    $('body').on('click', '.trainers__link, .trainer_link_info', function (event){
        Cookies.set('K_global_gets_pokemon', 0, { expires: 365 });
        Cookies.set('K_global_gets_trainer', 0, { expires: 365 });
        Cookies.set('page_pokemons', '1', { expires: 365 });

        navigate($(this).attr('data-href'));
    });

    $('body').off('click', '.pokemons__link, .pokemons__link_h')
    $('body').on('click', '.pokemons__link, .pokemons__link_h', function (event){
        Cookies.set('K_global_gets_pokemon', 0, { expires: 365 });
        Cookies.set('K_global_gets_trainer', 0, { expires: 365 });
        Cookies.set('page_pokemons', '1', { expires: 365 });

        if ($(this).attr('data-href-pokemon')){
            window.location.href = '/pokemon/'+$(this).attr('data-href-pokemon');
        }

        navigate($(this).attr('data-href'));
    });

    $('body').off('click', '.header__id');
    $('body').on('click', '.header__id', function (event){
        setTimeout(function (href){
            window.location.href = '/trainer/'+href;
        }, 500, $(this).attr('data-href'));
        return false;
    });

    $('body').on('click', '.top_menu_exit', function (event){
        $.ajax({
            url: site_config.new_api + '/technical_routes/auth_control',
            type: 'POST',
            dataType: 'json',
            data: {action: 'exit'},
            success: function(data){
                Cookies.set('user_id', '', { expires: 365 });
                Cookies.set('session_id', '', { expires: 365 });
                Cookies.set('user_image', '', { expires: 365 });

                Cookies.set('login', false, { expires: 365 });
                Cookies.set('telegram_name', false, { expires: 365 });
                Cookies.set('vk_auth_id', false, { expires: 365 });
                Cookies.set('telegram_call', false, { expires: 1 });

                Cookies.set('email_confirm', false, { expires: 365 });
                Cookies.set('telegram_add', 'false');
                Cookies.set('google_add', 'false');
                Cookies.set('user_auth', '0', { expires: 365 });
                setTimeout(function (){
                    window.location.href = '/login';
                    Cookies.set('user_auth', '0', { expires: 365 });
                }, 200);
            }, error: function (){
                $('.k_500').fadeIn();
                $('.k_trassing_template_all').css('display', 'none');
                $('.k_hide_block').css('display', 'none');
            }
        });
    });

    var url_local = window.location.pathname.split('/')[ 1 ];

    if (k_get('password_re')){
        $.ajax({
            url: site_config.new_api + '/trainers/re?key='+k_get('password_re')+'&trainer_id='+k_get('trainer_id'),
            type: 'GET',
            dataType: 'json',
            async: false,
            success: function(data){
                $('body').css('visibility', 'visible');
                if(data.status==='ok'){
                    window.location.href = '/new-pass';

                    Cookies.set('password_re', k_get('password_re'), { expires: 365 });
                    Cookies.set('user_re_id', k_get('trainer_id'), { expires: 365 });
                }
            }, error: function (jqXHR){
                $('body').css('visibility', 'visible');
                k_show_error_screen = jqXHR.status;
            }
        });
    }else{
        if ( !k_get('password_re') && !k_get('confirm_mail') && url_local != 'login' && url_local != 'new-pass' && url_local != 'new-pass-mail' &&
            url_local != 'profile' && url_local != 'confirmation2' &&
            url_local != 'confirmation' && url_local != 'registration' && url_local != 'registration-vk' &&
            url_local != 'confirmation-ok-2' && url_local != 'confirmation-ok-1' && url_local != 'registration-telegram' && url_local != '500' && url_local != '404'){
            if (Cookies.get('session_id') != '') {
                $.ajax({
                    url: site_config.new_api + '/technical_routes/auth_control',
                    type: 'POST',
                    dataType: 'json',
                    data: {action: 'auth', api_key: Cookies.get('session_id'), firebase_token: Cookies.get('firebase_token')},
                    success: function (data) {
                        $('body').css('visibility', 'visible');
                        if (data.hash) {
                            if ( k_get('password_re') || k_get('confirm_mail') || url_local == 'login' || url_local == 'new-pass' || url_local == 'new-pass-mail' ||
                                url_local == 'confirmation2' || url_local == 'confirmation' ||
                                url_local == 'registration'  || url_local == 'registration-vk' ||
                                url_local == 'confirmation-ok-2' || url_local == 'confirmation-ok-1' || url_local == 'registration-telegram'){
                                window.location.href = '/';
                                Cookies.set('user_auth', '0', { expires: 365 });
                            }

                            Cookies.set('user_id', data.id, { expires: 365 });
                            Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });

                            Cookies.set('login', data.login, { expires: 365 });
                            Cookies.set('telegram_name', data.telegram_name, { expires: 365 });
                            Cookies.set('vk_auth_id', data.vk_auth_id, { expires: 365 });
                            Cookies.set('user_auth', '1', { expires: 365 });
                            Cookies.set('is_premium', data.is_premium, { expires: 365 });
                            Cookies.set('premium_duration', data.premium_duration, { expires: 365 });

                            Cookies.set('email_confirm', data.email_confirm, { expires: 365 });
                            setTimeout(function(){
                                $('.header__id-text_type_profile').html(Cookies.get('user_id'));
                                $('.header__id').attr('data-href', Cookies.get('user_id'));
                                $('.status_url').attr('href', 'https://status.pokemonbattle.ru');

                                $('body').on('click', '.k_fix_profile_go', function (){
                                    window.location.href = '/trainer/'+Cookies.get('user_id')
                                })

                                if (Cookies.get('is_premium') === 'true'){
                                    $('.trainer_info_l_fix').attr('data-premium', true);

                                    $('.history-info-duration').css('display', 'flex');
                                    $('.k_active_premium').css('display', 'block');
                                    $('.button_cansel_premium').css('display', 'block');
                                    $('.history-info-duration-num').html(Cookies.get('premium_duration'));
                                    $('.history-info-duration-day').html(dayTitle(+Cookies.get('premium_duration')));
                                }else{
                                    $('.k_no_premium').css('display', 'block');
                                    MainFunc.check_button_top('k_page_main_premium .button_to_down', 'profile-mobile-wrapper', 56);
                                }
                                $('.k_load').removeClass('k_load');
                                $('.k_main_photo').attr('src', Cookies.get('user_image'));
                            }, 500)
                        }else{
                            window.location.href = '/login';
                            Cookies.set('user_auth', '0', { expires: 365 });
                        }
                    },
                    error: function (data){
                        window.location.href = '/login';
                        Cookies.set('user_auth', '0', { expires: 365 });
                    }
                });
            }else{
                window.location.href = '/login';
                Cookies.set('user_auth', '0', { expires: 365 });
            }
        }else{
            $.ajax({
                url: site_config.new_api + '/technical_routes/auth_control',
                type: 'POST',
                dataType: 'json',
                data: {action: 'auth', api_key: Cookies.get('session_id'), firebase_token: Cookies.get('firebase_token')},
                success: function (data) {
                    $('body').css('visibility', 'visible');
                    if (data.hash) {
                        if ( k_get('password_re') || k_get('confirm_mail') || url_local == 'login' || url_local == 'new-pass' || url_local == 'new-pass-mail' ||
                            url_local == 'confirmation2' || url_local == 'confirmation' ||
                            url_local == 'registration'  ||
                            url_local == 'confirmation-ok-2' || url_local == 'confirmation-ok-1'){
                            window.location.href = '/';
                            Cookies.set('user_auth', '0', { expires: 365 });
                        }
                        $('body').css('visibility', 'visible');

                        Cookies.set('user_id', data.id, { expires: 365 });
                        Cookies.set('user_image', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png', { expires: 365 });

                        Cookies.set('login', data.login, { expires: 365 });
                        Cookies.set('telegram_name', data.telegram_name, { expires: 365 });
                        Cookies.set('vk_auth_id', data.vk_auth_id, { expires: 365 });

                        Cookies.set('email_confirm', data.email_confirm, { expires: 365 });
                        Cookies.set('user_auth', '1', { expires: 365 });
                        setTimeout(function(){
                            $('.header__id-text_type_profile').html(Cookies.get('user_id'));
                            $('.header__id').attr('data-href', Cookies.get('user_id'));
                            $('.k_main_photo').attr('src', Cookies.get('user_image'));

                            $('.k_load').removeClass('k_load');
                            $('.k_main_photo').attr('src', Cookies.get('user_image'));
                        }, 500)
                    }
                },
                error: function (){
                    $('body').css('visibility', 'visible');
                }
            });
        }

        $.ajax({
            url: site_config.new_api + '/technical_routes/get_options',
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                console.log(data);

                $('a.footer__dok').attr('href', data.istr_silka);
                $('a.footer__rules').attr('href', data.istr_role);
                $('.footer__number').after('<div>Версия '+data.k_version+'</div>');
                $('.auth__version').html('Версия '+data.k_version);
                $('.login-mobile__subtitle').html('QA Studio v'+data.k_version);
                $('.footer__number').remove();
                $('body').append( data.k_js_append );
            }
        });
    }

    if (k_show_error_screen){
        return (
            <div className="App">
                <NotFound status={k_show_error_screen} title='Что-то пошло не&nbsp;так' />
            </div>
        );
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/trainers" element={<Coaches />} />
                <Route
                    path="/pokemon/:pokemonId"
                    element={
                        <PokemonSingle withBall={false} isDead={false} isGolden={true} />
                    }
                />
                <Route
                    path="/pokemon-loose"
                    element={
                        <PokemonSingleLoose
                            withBall={false}
                            isDead={true}
                            isGolden={false}
                        />
                    }
                />
                <Route
                    path="/pokemon-loose_legend"
                    element={
                        <PokemonSingle withBall={false} isDead={true} isGolden={true} />
                    }
                />
                <Route
                    path="/pokemon-noball"
                    element={
                        <PokemonSingle withBall={false} isDead={false} isGolden={false} />
                    }
                />
                <Route
                    path="/pokemon-original"
                    element={
                        <PokemonSingle withBall={true} isDead={false} isGolden={false} />
                    }
                />

                <Route path="/trainer/:trainerId" element={<TrainerItem isDead={false} />} />

                <Route path="/login" element={<Login />} />
                <Route path="/premium" element={<Premium />} />
                <Route path="/tracer" element={<Tracer />} />
                <Route path="/profile" element={<Profile />} />

                <Route path="/registration" element={<Registration />} />
                <Route path="/registration-vk/:Hash" element={<RegistrationVK />} />
                <Route path="/restore-pass" element={<RestorePassword />} />

                <Route
                    path="/confirmation"
                    element={
                        <Confirmation
                            title="Битва покемонов"
                            subtitle="Восстановление пароля"
                            text="Мы отправили подтверждение на вашу почту. Перейдите по ссылке в письме, чтобы продолжить"
                            text2="Нет письма? Проверьте папку «Спам»"
                        />
                    }
                />
                <Route
                    path="/confirmation2"
                    element={
                        <Confirmation
                            title="Битва покемонов"
                            subtitle="Регистрация"
                            text="Мы отправили подтверждение регистрации на вашу почту. Перейдите по ссылке в письме, чтобы завершить процесс регистрации"
                            text2=""
                        />
                    }
                />

                <Route
                    path="/confirmation-ok-1"
                    element={
                        <ConfirmationOk
                            title="Новый пароль сохранен"
                            text="Вы успешно сменили пароль"
                        />
                    }
                />
                <Route
                    path="/confirmation-ok-2"
                    element={
                        <ConfirmationOk
                            title="Регистрация завершена"
                            text="Вы успешно подтвердили вашу почту"
                        />
                    }
                />

                <Route path="/new-pass" element={<NewPassword />} />
                <Route path="/new-pass-mail" element={<NewPasswordMail />} />
                <Route path="/shop" element={<Shops />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route path="/payment/:PaymentId" element={<PaymentFormV2 />} />
                <Route path="/success" element={<SuccessPayment />} />
                <Route path="/buy-mistake" element={<BuyMistake />} />
                <Route path="/default" element={<ComponentDefolt name="Карта" />} />
                <Route path="/logs" element={<Loguru />} />
                <Route path="/rating" element={<Rating />} />

                <Route path="/pokemons/photos" element={<PokemonsGalereya />} />
                <Route path="/trainers/avatars" element={<TrainersGalereya />} />

                <Route path="" element={<NotFound status="404" title='Такая страница не&nbsp;найдена' />} />
                <Route path="*" element={<NotFound status="404" title='Такая страница не&nbsp;найдена' />} />
                <Route path="/404" element={<NotFound status="404" title='Такая страница не&nbsp;найдена' />} />
                <Route path="/500" element={<NotFound status="500" title='Что-то пошло не&nbsp;так' />} />
            </Routes>

            <NotFound is_all='k_is_all' status="500" is_over={false} title='Что-то пошло не&nbsp;так' />
        </div>
    );
}

export default App;
