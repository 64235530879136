import HeaderNo from "../Header/Header_no_work";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import errorStatus from "../../images/error-status.png";
import errorImg from "../../images/error-img.png";
import "./NotFound.css";
import Cookies from "js-cookie";

function NotFound({ status, title, is_over=true, is_hidden = 'block', is_all = '' }) {
    if (Cookies.get('user_auth') === '1'){
        return (
            <>
                {is_over && (<Header />)}
                <main id={is_all} className="content k_error_screen" style={{'display': is_hidden}}>
                    <div className="gradient"></div>
                    <div className="not-found container">
                        <div className='not-found__container'>
                            <h2 className="not-found__title">{title}</h2>
                            <div className="not-found__status">
                                <img
                                    src={errorStatus}
                                    className="not-found__status-bg"
                                    alt="облако"
                                />
                                <h1 className="not-found__text">{status}...</h1>
                            </div>
                            <img src={errorImg} className="not-found__pikachu" alt="пикачу" />
                        </div>
                    </div>
                </main>
                {is_over && (<Footer />)}
            </>
        );
    }else{
        return (
            <>
                {is_over && (<HeaderNo />)}
                <main id={is_all} className="content k_error_screen" style={{'display': is_hidden}}>
                    <div className="gradient"></div>
                    <div className="not-found container">
                        <div className='not-found__container'>
                            <h2 className="not-found__title">{title}</h2>
                            <div className="not-found__status">
                                <img
                                    src={errorStatus}
                                    className="not-found__status-bg"
                                    alt="облако"
                                />
                                <h1 className="not-found__text">{status}...</h1>
                            </div>
                            <img src={errorImg} className="not-found__pikachu" alt="пикачу" />
                        </div>
                    </div>
                </main>
                {is_over && (<Footer />)}
            </>
        );
    }


}

export default NotFound;
