import { useState, useEffect } from "react";
/*  Подключение компонентов   */
import Header from "../Header/Header";
import LayoutInfo from "../LayoutInfo/LayoutInfo";
import SingleImageElement from "./SingleImageElement";
import TitleSingle from "../TitleSingle/TitleSingle";
import HistoryBattls from "../HistoryBattls/HistoryBattls";
import TrainerInfo from "../TrainerInfo/TrainerInfo";
import BackButton from "../BackButton/BackButton";
import Footer from "../Footer/Footer";
/*  Подключение стилей   */
import "../Coaches/TrainerItem/TrainerItem.css";
import "../../index.css";
import emptyList from "../../images/pokemons/no-img.png";
/*  Доп.библиотеки   */
import Cookies from 'js-cookie';
import site_config from "../kenshi_config/kenshi_config"
import $ from "jquery";
/*  Картинки   */
import imageBall from "../../images/icons/ball.png";
import imageScull from "../../images/skull.svg";
import kenshi_config from "../kenshi_config/kenshi_config";
import {MainFunc} from "../kenshi_config/kenshi_functions";
import NotFound from "../NotFound/NotFound";


Cookies.set('K_global_gets_pokemon', 0, { expires: 365 });
Cookies.set('K_global_gets_trainer', 0, { expires: 365 });

/*  Экшены дебаг меню   */
$('body').on('click', '.k_debug_menu_c_delete_o_pokemon', function (){
    var is_delete = $(this).attr('data-is-delete');
    if (is_delete === 0 || is_delete === '') {
        $(this).attr('data-is-delete', 1);
        $(this).html('Вы подтверждаете удаление?');
    }

    if (+is_delete === 1){
        $(this).attr('data-is-delete', 2);
        $(this).html('Это действие не обратимо!');
    }

    if (+is_delete === 2){
        $(this).html('Покемон удален');

        var dataz = {}
        dataz['pokemon_id'] = +$('.k_cart_trainer_id .copy__text').html();
        var headers = { 'trainer_token': Cookies.get('session_id') }
        $.ajax({
            url: site_config.main_port_url + site_config.main_port + '/pokemons',
            type: 'DELETE',
            dataType: 'json',
            headers: headers,
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                setTimeout(function (){
                    Cookies.set('rerender_debug_menu', '1');
                }, 2000);
            }
        });
    }
})

function time_to_animate(thises, start, end, add, start_color, end_color){
    var shag = 0;
    var shag_val = 0;
    var new_shag = 0
    var direct = false;

    if (start > end){
        shag_val = (start - end);
        shag = shag_val/10;
    }else{
        shag_val = (end - start);
        shag = shag_val/10;
        direct = true;
    }

    thises = thises;
    for (var i= 0; i < 11; i++){
        if (direct == true){
            new_shag = (shag*i) + start
        }else{
            new_shag = start - (shag*i)
        }

        setTimeout(function (thises, new_shag, add){
            if (new_shag === 0 || new_shag === 100){
                add = 0;
            }

            thises.css('background', 'linear-gradient(90deg, '+start_color+' '+new_shag+'%, '+end_color+' '+(new_shag+add)+'%)');
        }, (30*i), thises, new_shag, add)
    }
}

$('body').on('click', '.k_debug_pokemon .k_debug_menu_c_kill_pokemon', function (){
    var hp = +$(this).attr('data-hp');
    var hp_add = 15;

    if (!$(this).hasClass('k_alive_pokemon')){
        hp -= 25;

        if (+hp <= 0) {
            $(this).addClass('k_alive_pokemon');
            $(this).html('Нокаут');
            $(this).closest('.k_debug_menu_c_pokemon_active').addClass('k_die')

            var dataz = {}
            dataz['pokemon_id'] = +$('.k_cart_trainer_id .copy__text').html();
            var thises_rem = $(this);
            $.ajax({
                url: site_config.new_api + '/debug_menu/knockout_pokemon',
                type: 'POST',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    var title_single__icon = $('.title-single__icon');

                    title_single__icon.html('<img src="' + imageBall + '" class="title-single__img">');
                    title_single__icon.attr('data-icon', false);
                    title_single__icon.attr('data-kill', false);
                    title_single__icon.first().html('<img src="' + imageScull + '" class="title-single__img">');
                    title_single__icon.first().attr('data-icon', true);
                    title_single__icon.first().attr('data-kill', true);
                    $('.k_pok_change_pokeball').addClass('k_die');
                    $('.k_debug_pokemon').addClass('k_die');
                    $('.trainer-img').addClass('trainer-img-dead');
                    Cookies.set('rerender_debug_menu', '1');
                }
            });
        }

        time_to_animate($(this), +$(this).attr('data-hp'), hp, hp_add, '#258f25', 'red');
        $(this).attr('data-hp', hp);
    }

})

var s_int = '';
$('body').on('keyup', '.k_debug_pokemon .k_debug_menu_c_input', function () {
    clearTimeout(s_int)
    var thises = $(this);
    s_int = setTimeout(function (thises){
        if (thises.val() !== '') {
            $('.k_main_error_text').remove();
            var dataz = {}
            var headers = {'trainer_token': Cookies.get('session_id')}
            dataz[thises.attr('data-name')] = thises.val();
            dataz['pokemon_id'] = +$('.k_cart_trainer_id .copy__text').html();
            $('.k_main_error_text').remove();
            $.ajax({
                url: site_config.new_api + '/debug_menu/update_pokemon',
                type: 'PATCH',
                dataType: 'json',
                headers: headers,
                contentType: "application/json",
                data: JSON.stringify(dataz),
                success: function (data) {
                    if (thises.attr('data-name') === 'attack') {
                        $('.k_cart_trainer_attack').html(thises.val());
                        $('.k_cart_trainer_attack + ul > li').attr('data-active', thises.val());
                        Cookies.set('rerender_debug_menu_attack', thises.val());
                    }

                    if (thises.attr('data-name') === 'stage') {
                        $('.k_cart_trainer_stage').html(thises.val());
                        $('.k_cart_trainer_stage + ul > li').attr('data-active', thises.val());
                        Cookies.set('rerender_debug_menu_stage', thises.val());
                    }

                    if (thises.attr('data-name') === 'photo_id') {
                        if (thises.val() === -1) {
                            $.ajax({
                                url: site_config.main_port_url + site_config.main_port + '/pokemons?pokemon_id=' + $('.k_cart_trainer_id .copy__text').html() + '&page=0',
                                type: 'GET',
                                dataType: 'json',
                                headers: { 'trainer_token': Cookies.get('session_id') },
                                async: false,
                                success: function (data) {
                                    if (data.photo_id) {
                                        data.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(data.photo_id+'').padStart(3, '0')+'.png';
                                    } else {
                                        data.photo = emptyList;
                                    }

                                    $('img.trainer-img').attr('src', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + data.avatar_id).padStart(2, '0')+'.png');
                                }
                            })
                        } else {
                            $('img.trainer-img').attr('src', 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + thises.val()).padStart(2, '0')+'.png')
                        }

                        Cookies.set('rerender_debug_menu', '1');
                    } else {
                        $('.k_cart_trainer_' + thises.attr('data-name')).html(thises.val());
                        Cookies.set('rerender_debug_menu_name', '.k_cart_trainer_' + thises.attr('data-name') + '::' + thises.val());
                    }
                },
                error: function (data) {
                    data = JSON.parse(data.responseText);

                    thises.after('<font class="k_main_error_text k_f_error">' + data.message + '</font>')
                }
            });
        }

        clearTimeout(s_int);
    }, 1200, thises)

})

$('body').on('click', '.k_debug_pokemon .k_debug_menu_c_pokemon_pokeball_change', function (){
    var dataz = {}
    dataz['pokemon_id'] = +$('.k_cart_trainer_id .copy__text').html();
    dataz['trainer_id'] = +$('.trainer_link_info .copy__text').html();

    if (+$(this).attr('data-status') === 1){
        $('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
        $(this).html('Привязать');
        $(this).attr('data-status', 0);

        $.ajax({
            url: site_config.new_api + '/debug_menu/delete_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                var title_single__icon = $('.title-single__icon');

                title_single__icon.html('<img src="'+imageBall+'" class="title-single__img">');
                title_single__icon.attr('data-icon', false);
                title_single__icon.attr('data-kill', false);
                Cookies.set('rerender_debug_menu', '1');
            }
        });
    }else{
        $('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
        $(this).html('Отвязать');
        $(this).attr('data-status', 1)

        $.ajax({
            url: site_config.new_api + '/debug_menu/add_pokeball',
            type: 'POST',
            dataType: 'json',
            headers: { 'trainer_token': Cookies.get('session_id') },
            contentType: "application/json",
            data: JSON.stringify(dataz),
            success: function (data) {
                var title_single__icon = $('.title-single__icon');

                title_single__icon.first().html('<img src="'+imageBall+'" class="title-single__img">');
                title_single__icon.first().attr('data-icon', true);
                title_single__icon.first().attr('data-kill', false);
                Cookies.set('rerender_debug_menu', '1');
            }
        });
    }
})

var pokemon_info = {};
var trainer_info = {};
var pok_name = 'Покемон';
const PokemonSingle = () => {
  const [data, setData] = useState("");
  const [pokemon_name, setPokemon_name] = useState("");
  const [pokemon_attack, setPokemon_attack] = useState("");
  const [pokemon_stage, setPokemon_stage] = useState("");
  const [pokemon_photo_id, setPokemon_photo_id] = useState("");

    if (pokemon_info === false){
        Cookies.set('K_global_gets_pokemon', 0, { expires: 365 });
    }

    useEffect(() => {
        if ($('.trainer-img')[0]) {
            $('.single-gradient').css('left', (($('.trainer-img').offset().left - $('.single-gradient').width() / 2) + ($('.trainer-img').width() / 2) - 50) + 'px');
        }
    }, [])

    useState(() => {
        if (Cookies.get('K_global_gets_trainer') === 0 || 1 === 1) {
            var pokemon_id = window.location.pathname.split('/');
            $.ajax({
                url: site_config.new_api + '/pokemons?pokemon_id=' + pokemon_id[pokemon_id.length - 1] + '&page=0',
                type: 'GET',
                dataType: 'json',
                headers: { 'trainer_token': Cookies.get('session_id') },
                async: false,
                success: function (data) {
                    if (!data.data){
                        MainFunc.render_404();
                        return;
                    }

                    pokemon_info = data.data[0];

                    if (pokemon_info.photo_id) {
                        pokemon_info.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(pokemon_info.photo_id+'').padStart(3, '0')+'.png';
                    } else {
                        pokemon_info.photo = emptyList;
                    }

                    if (pokemon_info.legend) {
                        pok_name = 'Легендарный покемон';
                    } else {
                        pokemon_info.legend = false;
                    }

                    setPokemon_name(pokemon_info.name);
                    setPokemon_attack(Math.round(pokemon_info.attack));
                    setPokemon_stage(Math.round(pokemon_info.stage));
                    setPokemon_photo_id(Math.round(pokemon_info.photo_id));
                    pokemon_info.attack = Math.round(pokemon_info.attack);

                    if (pokemon_info.in_pokeball === 1) {
                        pokemon_info.in_pokeball = true;

                        setTimeout(function () {
                            $('.k_debug_menu_c_pokemon_pokeball_status').html('Привязан к покетболу');
                            $('.k_debug_menu_c_pokemon_pokeball_change').html('Отвязать');
                            $('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 1);
                        }, 500);
                    } else {
                        pokemon_info.in_pokeball = false;

                        setTimeout(function () {
                            $('.k_debug_menu_c_pokemon_pokeball_status').html('Не привязан к покетболу');
                            $('.k_debug_menu_c_pokemon_pokeball_change').html('Привязать');
                            $('.k_debug_menu_c_pokemon_pokeball_change').attr('data-status', 0);
                        }, 500);
                    }

                    if (pokemon_info.status === 0) {
                        pokemon_info.status = true;

                        setTimeout(function () {
                            $('.k_pok_change_pokeball').addClass('k_die');
                            $('.k_debug_pokemon').addClass('k_die');
                            $('.k_debug_menu_c_kill_pokemon').attr('data-hp', 0);
                            $('.k_debug_menu_c_kill_pokemon').html('Нокаут');
                            $('.k_debug_menu_c_kill_pokemon').addClass('k_alive_pokemon');
                            $('.k_debug_menu_c_kill_pokemon').css('background', 'linear-gradient(90deg, #258f25 0%, red 0%)');
                        }, 500);
                    } else {
                        pokemon_info.status = false;
                    }

                    setTimeout(function () {
                        $('.feature.trainer-item__id span.feature-name').html('ID покемона');
                    }, 300);

                    if (+Cookies.get('user_id') === +pokemon_info.trainer_id) {
                        setTimeout(function () {
                            if (Cookies.get('is_debug') === '1') {
                                $('.k_debug_menu').css('visibility', 'visible');
                                Cookies.set('allow_debug_menu', '1');
                            } else {
                                $('.k_debug_menu').remove();
                                Cookies.set('allow_debug_menu', '0');
                            }
                        }, 500);
                    } else {
                        setTimeout(function () {
                            $('.k_debug_menu').remove();
                        }, 500);
                    }

                    $.ajax({
                        url: site_config.new_api + '/trainers?trainer_id=' + pokemon_info.trainer_id,
                        type: 'GET',
                        dataType: 'json',
                        headers: { 'trainer_token': Cookies.get('session_id') },
                        async: false,
                        success: function (data) {
                            data = data.data[0];
                            trainer_info = data;
                            trainer_info.link = '/trainer/' + trainer_info.id;
                            if (trainer_info.level >= 5) {
                                trainer_info.trainer_legend = 1
                            }
                            if (trainer_info.is_premium) {
                                trainer_info.trainer_premium = 1
                            }

                            trainer_info.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/trainers/'+('' + trainer_info.avatar_id).padStart(2, '0')+'.png';
                        }
                    });

                    var last_element = pokemon_info;
                    var global_battles = 0;
                    var global_battles_date = [];
                    var i_battle = 0;

                    $('.k_trassing_block_battles_fix').remove();
                    $.ajax({
                        url: site_config.new_api + '/battle?pokemon_id=' + pokemon_info.id,
                        type: 'GET',
                        headers: { 'trainer_token': Cookies.get('session_id') },
                        dataType: 'json',
                        success: function (data) {
                            data = data.data;

                            try {
                                data = data.reverse();
                                for (var i = 0; i < data.length; i++) {

                                    var element = $('.k_trassing_block_battles_none').clone();

                                    element.find('.k_trassing_r_my_pokemon img').attr('src', last_element.photo);

                                    if (data[i].loser === last_element.id) {
                                        var attack = Math.round(data[i].loser_attack);
                                        var enemny_attack = Math.round(data[i].winner_attack);
                                    } else {
                                        var attack = Math.round(data[i].winner_attack);
                                        var enemny_attack = Math.round(data[i].loser_attack);
                                    }
                                    element.find('.k_trassing_r_my_pokemon').attr('data-id', last_element.id);

                                    element.find('.k_trassing_r_my_pokemon .history-card__title').html(last_element.name);

                                    element.find('.k_trassing_r_my_pokemon .level').attr('data-level', attack);
                                    element.find('.k_trassing_r_my_pokemon .level__name').attr('data-level', attack);
                                    element.find('.k_trassing_r_my_pokemon .level__count').attr('data-level', attack).html(attack);

                                    element.find('.k_trassing_r_other_pokemon .level').attr('data-level', enemny_attack);
                                    element.find('.k_trassing_r_other_pokemon .level__name').attr('data-level', enemny_attack);
                                    element.find('.k_trassing_r_other_pokemon .level__count').attr('data-level', enemny_attack).html(enemny_attack);

                                    element.find('.k_trassing_r_my_pokemon .pok_id').text(last_element.id);
                                    element.find('.k_trassing_r_my_pokemon .pokemons__link_h').attr('data-href-pokemon', last_element.id);

                                    var date = new Date(data[i].date);
                                    element.find('.date').text(date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear().toString().substr(-2));

                                    element.addClass('k_battle_' + i_battle);
                                    global_battles_date[i_battle] = [];
                                    global_battles_date[i_battle]['ids'] = i_battle;

                                    var date_int = Number(date);
                                    global_battles_date[i_battle]['dates'] = date_int;
                                    i_battle++;

                                    if (data[i].loser === last_element.id) {
                                        var battle_enemy = data[i].winner;
                                        var k_status = 0
                                    } else {
                                        var battle_enemy = data[i].loser;
                                        var k_status = 1
                                    }

                                    if (k_status === 1) {
                                        element.find('.history-item__bottom').addClass('victory');
                                        element.find('.history-item__bottom').removeClass('defeat');
                                        element.find('.history-item__bottom span').html('Победа ' + last_element.name + '!');
                                        element.find('.history-item__bottom img').remove();

                                        element.find('.k_trassing_r_other_pokemon').addClass('defeat');
                                    } else {
                                        element.find('.history-item__bottom').removeClass('victory');
                                        element.find('.history-item__bottom').addClass('defeat');
                                        element.find('.history-item__bottom span').html('Поражение ' + last_element.name + '!');

                                        element.find('.k_trassing_r_my_pokemon').addClass('defeat');
                                    }

                                    if (data[i].attacking_pok_id === last_element.id) {
                                        element.find('.k_trassing_r_other_pokemon').addClass('defeat_k');
                                        element.find('.k_trassing_r_my_pokemon').addClass('victory');
                                    } else {
                                        element.find('.k_trassing_r_my_pokemon').addClass('defeat_k');
                                        element.find('.k_trassing_r_other_pokemon').addClass('victory');
                                    }

                                    element.removeClass('k_trassing_block_battles_none');
                                    element.addClass('k_trassing_block_battles_fix');
                                    element.fadeIn();

                                    element.find('.k_trassing_r_other_pokemon').addClass('pokemon_ajax_' + battle_enemy);
                                    $.ajax({
                                        url: site_config.new_api + '/pokemons?pokemon_id=' + battle_enemy,
                                        type: 'GET',
                                        headers: { 'trainer_token': Cookies.get('session_id') },
                                        dataType: 'json',
                                        success: function (data) {
                                            data = data.data[0];

                                            var enemy_pok = $('.pokemon_ajax_' + data.id);
                                            if (data.photo_id) {
                                                    data.photo = 'https://storage.yandexcloud.net/qastudio/pokemon_battle/pokemons/'+(data.photo_id+'').padStart(3, '0')+'.png';
                                            } else {
                                                data.photo = emptyList;
                                            }

                                            enemy_pok.find('img').attr('src', data.photo);
                                            enemy_pok.find('.history-card__title').text(data.name);
                                            enemy_pok.find('.pok_id').text(data.id);
                                            enemy_pok.find('.pokemons__link_h').attr('data-href-pokemon', data.id);
                                            enemy_pok.attr('data-id', data.id);
                                        }, error: function () {
                                            $('.k_500').fadeIn();
                                            $('.k_trassing_template_all').css('display', 'none');
                                            $('.k_hide_block').css('display', 'none');
                                        }
                                    });

                                    $('.k_trassing_repeater_battles').append(element);
                                    global_battles++;
                                }
                            } catch (e) {
                                //pass
                            }

                            $('.history-info_count').html(global_battles);
                            if ($(window).width() > 768) {
                                if (global_battles <= 3) {
                                    $('.history__list').css('height', 'auto');
                                } else {
                                    $('.history__list').css('height', '400px');
                                }
                            }

                            if (global_battles === 0) {
                                $('.trainer-item__history').addClass('feature-empty');
                            } else {
                                $('.trainer-item__history').removeClass('feature-empty');
                            }
                        }, error: function () {
                            $('.k_500').fadeIn();
                            $('.k_trassing_template_all').css('display', 'none');
                            $('.k_hide_block').css('display', 'none');
                        }
                    });
                }, error: function () {
                    window.location.href = '/500';
                }
            })
            Cookies.set('K_global_gets_trainer', 1, {expires: 365});
        }
    });

  if (MainFunc.k_get('debug_trainer_active') === "1"){
      return (
          <>
              <main
                  className={
                      data !== ""
                          ? "content pok-single his-open container"
                          : "content pok-single container"
                  }
              >
                  <div className='k_debug_menu'>
                      <div className='k_debug_menu_console k_debug_pokemon'>
                          <div className='k_debug_menu_c_title'>Редактировать покемона</div>

                          <div className='k_debug_menu_c_flex'>
                              <label>Имя</label>
                              <input type='text' data-name='name'
                                     className='k_debug_menu_c_input k_debug_menu_c_pokemon_name'
                                     defaultValue={pokemon_name}/>
                          </div>

                          <div className='k_debug_menu_c_flex'>
                              <label>Атака</label>
                              <input type='number' data-name='attack'
                                     className='k_debug_menu_c_input k_debug_menu_c_pokemon_attack'
                                     defaultValue={pokemon_attack}/>
                          </div>

                          <div className='k_debug_menu_c_flex'>
                              <label>Стадия</label>
                              <input type='number' data-name='stage'
                                     className='k_debug_menu_c_input k_debug_menu_c_pokemon_stage'
                                     defaultValue={pokemon_stage}/>
                          </div>

                          <div className='k_debug_menu_c_flex'>
                              <label>Картинка(ID)</label>
                              <input type='number' data-name='photo_id'
                                     className='k_debug_menu_c_input k_debug_menu_c_pokemon_photo' defaultValue={pokemon_photo_id}/>
                          </div>

                          <div className='k_debug_menu_c_flex k_pok_change_pokeball'>
                              <div className='k_debug_menu_c_pokemon_pokeball_status'>Не привязан к покетболу</div>
                              <div className='k_debug_menu_c_b k_debug_menu_c_pokemon_pokeball_change'>Привязать
                              </div>
                          </div>

                          <div className="content__box" style={{display:'none'}}>
                              <LayoutInfo
                                  id={pokemon_info.id}
                                  city=""
                                  level=""
                                  stage={pokemon_info.stage}
                                  attack={pokemon_info.attack}
                                  countsPokemons=""
                              />

                              <TrainerInfo
                                  img={trainer_info.photo}
                                  icon={trainer_info.trainer_legend}
                                  icon_premium={trainer_info.trainer_premium}
                                  status="Тренер"
                                  name={trainer_info.trainer_name}
                                  level={trainer_info.level}
                                  copyCount={trainer_info.id}
                                  link={trainer_info.link}
                              />
                          </div>

                          <div className='k_debug_menu_c_b k_debug_menu_c_kill_pokemon' data-hp='100'>В нокаут</div>
                      </div>
                  </div>
              </main>
          </>
      );
  } else {
      setInterval(function (){
          if (Cookies.get('rerender_debug_menu') === '1'){
              window.location.reload();
              Cookies.set('rerender_debug_menu', '0');
          }
          if (Cookies.get('rerender_debug_menu_attack') && Cookies.get('rerender_debug_menu_attack') !== 'false'){
              $('.k_cart_trainer_attack').html(Cookies.get('rerender_debug_menu_attack'));
              $('.k_cart_trainer_attack + ul > li').attr('data-active', Cookies.get('rerender_debug_menu_attack'));
              Cookies.set('rerender_debug_menu_attack', 'false');
          }
          if (Cookies.get('rerender_debug_menu_stage') && Cookies.get('rerender_debug_menu_stage') !== 'false'){
              $('.k_cart_trainer_stage').html(Cookies.get('rerender_debug_menu_stage'));
              $('.k_cart_trainer_stage + ul > li').attr('data-active', Cookies.get('rerender_debug_menu_stage'));
              Cookies.set('rerender_debug_menu_stage', 'false');
          }
          if (Cookies.get('rerender_debug_menu_name') && Cookies.get('rerender_debug_menu_name') !== 'false'){
              var name_and_class = Cookies.get('rerender_debug_menu_name').split('::');
              $(name_and_class[0]).html(name_and_class[1]);
              Cookies.set('rerender_debug_menu_name', 'false');
          }
      }, 1000);

      if (!pokemon_info.name){
          return (
              <NotFound status="404" is_over="1"/>
          );
      }
      return (
          <>
              <Header/>
              <main
                  className={
                      data !== ""
                          ? "content pok-single his-open container"
                          : "content pok-single container"
                  }
              >
                  <section className="trainer-item">
                      <BackButton link="javascript:history.back()"/>
                      <SingleImageElement isDead={!pokemon_info.status} image={pokemon_info.photo}/>

                      <div className="trainer-item__content">
                          <TitleSingle
                              subtitle={pok_name}
                              title={pokemon_info.name}
                              iconBall={pokemon_info.in_pokeball}
                              iconCoin={pokemon_info.legend}
                              iconScull={pokemon_info.status}
                              iconCup={pokemon_info.legend}
                              kill={pokemon_info.status}
                          />
                          <div className="content__inner">
                              <div className="content__box content__box_pokemon_top">
                                  <LayoutInfo
                                      id={pokemon_info.id}
                                      city=""
                                      level=""
                                      stage={pokemon_info.stage}
                                      attack={pokemon_info.attack}
                                      countsPokemons=""
                                  />

                                  <TrainerInfo
                                      img={trainer_info.photo}
                                      icon={trainer_info.trainer_legend}
                                      icon_premium={trainer_info.trainer_premium}
                                      status="Тренер"
                                      name={trainer_info.trainer_name}
                                      level={trainer_info.level}
                                      copyCount={trainer_info.id}
                                      link={trainer_info.link}
                                  />
                              </div>
                              <HistoryBattls
                                  select={false}
                              />
                          </div>
                      </div>
                  </section>
              </main>
              <Footer/>
          </>
      );
  }
};

export default PokemonSingle;
