import React from "react";
import "./FilterPokemon.css";
import Checkbox from "../Checkbox";

function FilterPokemon(props) {
  
  const popupClassName = props.isOpenFilter
      ? "popup-filter_opened filter_power"
      : "popup-filter filter_power";

  const popupMobileClassName = props.isOpenFilter
      ? "popup-filter-mobile_opened filter_power"
      : "popup-filter-mobile filter_power";

  //Закрытие мобильного попапа
  function closeMobilePopup() {
    props.onclose();
    //props.setStateBtnFilter(true);
  }

  return (
      <div>
        <div className={popupClassName}>
          <div className="popup__filter-container">
            <h3 className="popup__title">Фильтры</h3>
            <button
                type="button"
                className={
                  props.hiddenReset
                      ? "filter__button-reset_show filter__button-reset"
                      : "filter__button-reset"
                }
                onClick={props.counterSelectedDelete}
                hidden={props.hiddenReset}
            >
              Сбросить
            </button>
          </div>
          <div className="popup__filter">
            <Checkbox
                element="+"
                name="Атака"
                counterSelected={props.counterSelected}
            />
          </div>
        </div>

        <div className={popupMobileClassName}>
          <div className="popup-filter-mobile__container">
            <button
                type="button"
                className="popup-mobile__btn-close"
                onClick={closeMobilePopup}
            ></button>
            <h3 className="popup__title">Фильтры</h3>
            
            <div className="popup__filter">
              <Checkbox
                  element="+"
                  name="Атака"
                  counterSelected={props.counterSelected}
                  data-hidden={props.hidden}
              />
              <button type="button" className="popup-mobile__btn">
                Применить
              </button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default FilterPokemon;