import React from "react";

const SingleImageElement = ({ image, isDead }) => {
  return (
    <div className="trainer-item__img k_cart_trainer_img">
      <div className="single-gradient"></div>
      <img
        className={!isDead ? "trainer-img trainer-img-dead" : "trainer-img"}
        src={image}
        alt="image"
      />
    </div>
  );
};

export default SingleImageElement;
