import React from "react";
import shit from '../../../images/defeat.png';
import knife from '../../../images/sword.png';




function HistoryItem(props) {
  
    const userImageStyle =props.isWinner  ? 'history__icon' : 'history__icon history__icon_type-dead';
    const userScoreStyle = props.isWinner ? 'history__counter' : 'history__counter-dead history__counter';
    const nameStyle = props.isWinner ? "history__name" : 'history__name-dead';
    const attackStyle = props.isWinner ? "history__attack" : 'history__attack history__attack_type-dead';
    const enemyScoreStyle = !props.isWinner ? "history__counter-enemy" : 'history__counter-enemy history__counter-dead-enemy';
    const enemyNameStyle = !props.isWinner ? "history__name" : 'history__name-dead';
    const enemyAttackStyle = !props.isWinner ? "history__attack" : 'history__attack history__attack_type-dead';
    const enemyImageStyle = !props.isWinner ? 'history__icon' : 'history__icon history__icon_type-dead';
    return (
        <li  className="history__item" >
            {!props.isLast ? <div className="history__win">{`Победа ${props.name}!`}</div> : <div className='history__loose'>{`Поражение ${props.name}!`}
                <span className='history__dead'></span>
            </div>}
            <div className="history__container">
                <time className="history__data">{props.data}</time>
                <img alt='pokemon' src={props.userImage} className={userImageStyle} />
                <span className={userScoreStyle}>{props.userScore}</span>
                <div className="history__info">
                    <p className={nameStyle}>{props.name}</p>
                    <div className={attackStyle}>{`Атака ${props.userAttack}`}</div>
                </div>
            </div>
            {/* щит и меч */}
            <div className="history__attributes">
                <img className='history__atr-icon' alt='atribut' src={shit} />
                <img className='history__atr-icon' alt='atribut' src={knife} />
            </div>
            {/* щит и меч */}
            <div className="history__container">
                <img alt='pokemon' src={props.enemyImage} className={enemyImageStyle} />
                <span className={enemyScoreStyle}>{props.enemyScore}</span>
                <div className="history__info">
                    <p className={enemyNameStyle}>{props.enemyName}</p>
                    <div className={enemyAttackStyle}>{`Атака ${props.enemyAttack}`}</div>
                </div>
            </div>
        </li>
    )
}

export default HistoryItem;