import './Shop.css';

function Shop() {
    return (
        <section className="pokemon" >
            <div className="pokemon__content">
                <h1 className="pokemon__title">Магазин</h1>
            </div>
        </section>
    )
}
export default Shop;