import React from "react";
import "./Search.css";
import search from "../../../images/search.svg";
import close from '../../../images/close-popup.svg';

function Search() {
  const counter = window.innerWidth > 992;

  const [disabledButton, setDisabledButton] = React.useState(true);
  const [state, setState] = React.useState(false);
  const [closeBtn, setCloseBtn] = React.useState(false);

  const input = document.getElementById('search-input')

  //Открытие формы поиска
  function formOpen(e) {
    setState(true);
    setDisabledButton(false);
    const elem = e.target.closest('.filter-wrapper')
    const search = elem.querySelector('.pokemon__sort');
    const filter = elem.querySelector('.pokemon__filter-btn');
    const focus = elem.querySelector('.form-search__input');
    search.classList.add('pokemon__sort_hidden');
    filter.classList.add('pokemon__filter-btn_hidden');
    focus.focus();
  }
  

  function handleChange(event) {

    let count = event.target.value;
    if(count.length >= 1) {
      setCloseBtn(true);
    }  else if(count.length == 0) {
      setCloseBtn(false);
    }
  }

  function clearInput() {
    input.value = '';
    setCloseBtn(false);
  }

  
  //Закрытие формы поиска
  function closeForm(e) {
    e.preventDefault();
    setState(false);
    setDisabledButton(true);
    const elem = e.target.closest('.filter-wrapper')
    const search = elem.querySelector('.pokemon__sort');
    const filter = elem.querySelector('.pokemon__filter-btn');
    search.classList.remove('pokemon__sort_hidden');
    filter.classList.remove('pokemon__filter-btn_hidden');
  }

  return (
    <>
        {counter && (
          <form className='form-search' name="search">
            <div className='form-search__img'>
              <img src={search} alt="лупа" />
            </div>
            <input
                id='search-input'
                hidden={state}
                type="text"
                className="form-search__input"
                placeholder="Поиск"
                onChange={handleChange}
            />
            <img className="form__img-close" src={close} alt="крестик" data-hidden={closeBtn} onClick={clearInput} />
            <button className={!state ? 'form-search__close': 'form-search__close active'} onClick={closeForm} />
          </form>
        )}
        {!counter && (
          <form className={!state ? 'form-search' : 'form-search active'} name="search">
            <div className='form-search__img' onClick={formOpen} >
              <img src={search} alt="лупа" />
            </div>
            <input
                id='search-input'
                type="text"
                className={!state ? "form-search__input" : "form-search__input active" }
                placeholder="Поиск"
                onChange={handleChange}
            />
            <img className="form__img-close" src={close} alt="крестик" data-hidden={closeBtn} onClick={clearInput} />
            <button className={!state ? 'form-search__close': 'form-search__close active'} onClick={closeForm} />
        </form>
        )}
    </>
  );
}

export default Search;
