/*  Подключение компонентов   */
import React from "react";
import Header from "../Header/Header";
import Pokemon from "../Pokemon/Pokemon";
import CardList from "../CardList/CardList";
import Footer from "../Footer/Footer";
/*  Подключение стилей   */
import "./Main.css";

function Main() {
    return (
        <>
            <Header/>
            <main className="content">
                <div className="gradient"></div>
                <Pokemon/>
                <CardList/>
            </main>
            <Footer/>
        </>
    );
}

export default Main;
