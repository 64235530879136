import React from "react";
import "./LayoutInfo.css";
import Copy from "../Copy/Copy";
import Status from "./Status/Status";
import Achievement1 from "../../images/icons/Achievement1.png";
import Achievement2 from "../../images/icons/Achievement2.png";
import Achievement3 from "../../images/icons/Achievement3.png";
import Achievement4 from "../../images/icons/Achievement4.png";
import Achievement5 from "../../images/icons/Achievement5.png";
import Achievement6 from "../../images/icons/Achievement6.png";
import Achievement7 from "../../images/icons/Achievement7.png";
import Tip_Arrow from "../../images/icons/Tip Arrow.png";

const LayoutInfo = ({ id, city, level, stage, attack, countsPokemons }) => {
  return (
    <>
      <div className="trainer-item__info">
        <div className="feature trainer-item__rate">
          <span className="feature-name">Место</span>
          <span className="feature-text k_cart_trainer_rate" style={{'width': 'fit-content'}}>~</span>
          <div className="k_cart_trainer_rate_img"></div>
        </div>

        <div className="feature trainer-item__id">
          <span className="feature-name">ID тренера</span>
          <div className="feature-text id__wrapper k_cart_trainer_id">
            <Copy copyCount={id} hidden={false} />
          </div>
        </div>

        {city && (
          <div className="feature trainer-item__city">
            <span className="feature-name">Город</span>
            <span className="feature-text k_cart_trainer_city">{city ? city : "Токио"}</span>
          </div>
        )}

        {stage != "" && (
          <div className="feature trainer-item__level">
            <span className="feature-name">Стадия</span>
            <span className="feature-text k_cart_trainer_stage">{stage ? stage : 0}</span>
            <Status active={stage} full={3} />
          </div>
        )}

        {attack && (
          <div className="feature trainer-item__level">
            <span className="feature-name">Атака</span>
            <span className="feature-text k_cart_trainer_attack">{attack ? attack : 0}</span>
            <Status active={attack} full={7} />
          </div>
        )}

        {countsPokemons && (
            <div className="feature trainer-item__total-pokemons">
              <span className="feature-name">Покеболы</span>
              <span className="feature-text k_cart_trainer_pokemons">
              0
            </span>
              <Status active="0" full={3} />
            </div>
        )}

        {level && (
          <div className="feature trainer-item__level">
            <span className="feature-name">Уровень</span>
            <span className="feature-text k_cart_trainer_level">{level ? level : 0}</span>
            <Status active={level} full={5} />
          </div>
        )}

        <div className="achievements">
          <div className="achievements_icon beginning-icon" data-slug="beginning">
            <img src={Achievement1} width="30px" height="30px"/>
          </div>
          <div className="achievements_icon out_of_battles-icon" data-slug="out_of_battles">
            <img src={Achievement2} width="32px" height="32px"/>
          </div>
          <div className="achievements_icon self_knockout-icon" data-slug="self_knockout">
            <img src={Achievement3} width="28px" height="28px"/>
          </div>
          <div className="achievements_icon max_level-icon" data-slug="max_level">
            <img src={Achievement4} width="30px" height="30px"/>
          </div>
          <div className="achievements_icon one_vs_seven-icon" data-slug="one_vs_seven">
            <img src={Achievement5} width="30px" height="30px"/>
          </div>
          <div className="achievements_icon five_battles-icon" data-slug="five_battles">
            <img src={Achievement6} width="28px" height="28px" style={{'width': '28px', 'height': '28px', }}/>
          </div>
          <div className="achievements_icon five_defends-icon" data-slug="five_defends">
            <img src={Achievement7} width="28px" height="28px" style={{'width': '28px', 'height': '28px', }}/>
          </div>

          <div className="achievements_popup">
            <div className="achievements_popup_top"><img src={Tip_Arrow}/></div>
            <div className="achievements_popup_content">
              <div className="achievements_popup_content_title">Будет что вспомнить</div>
              <div className="achievements_popup_content_txt">Отправил своего покемона в нокаут</div>
            </div>
          </div>

        </div>

      </div>
    </>
  );
};

export default LayoutInfo;
