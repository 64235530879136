 import React from "react"; 
import './History.css';
import HistoryItem from "../HistoryItem/HistoryItem";
import smallPikachu from '../../../images/pokemon-1.jpg';
import pokemon2 from '../../../images/pokemon-3.jpg';
import pokemon3 from '../../../images/pokemon-4.jpg';

function History(props) {
    const [scrollPos, setScrollPos] = React.useState(false)
    const ref = React.useRef()
    
    const setScroll = () => {
        if (ref.current.scrollTop >= 20) {
            setScrollPos(true)
            return
        }
        if (ref.current.scrollTop <= 20) {
            setScrollPos(false)

        }
        
    }

    return (
        <ul ref={ref} className={scrollPos ? 'history history-scroll history-top-scroll' : "history history-top-scroll"} onScroll={setScroll}>
            <HistoryItem
                isLast={!props.isWinner}
                isWinner={props.isWinner}
               /*  isWinnerForLooseCase={props.isWinner} */
                name='Пикачу'
                enemyName='Пукапука'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
            <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбаза...'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
            <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Райчу'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon2} />
            <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбазавр'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
            <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбаза...'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
            <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбаза...'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
             <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбаза...'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
             <HistoryItem
                isLast={false}
                isWinner={true}
                name='Пикачу'
                enemyName='Бульбаза...'
                userScore={1001}
                enemyScore={2005}
                data='29.04.2023'
                userAttack={5}
                enemyAttack={20}
                userImage={smallPikachu}
                enemyImage={pokemon3} />
        </ul>


    )
}

export default History;