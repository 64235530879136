import "./Bol.css";

function Bol({ active }) {
  return (
    <>
      <div className="bol" data-bol={active}>
        <div className="bol__icon"></div>
        <div className="bol__icon"></div>
        <div className="bol__icon"></div>
      </div>
    </>
  );
}

export default Bol;
